.payment-method {
  align-items: flex-start;
  border-bottom: 0.1rem solid #e0d3d3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;

  &:last-child {
    border: none;
  }

  &__details {
    width: 100%;
  }

  li {
    color: $darkGrey;
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.1rem;
    
    .status-indicator {
      min-width: 8rem;
    }
  }

  &__services {
    li {
      font-weight: normal;
    }
  }
}
