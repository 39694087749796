.mobile-header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;

  overflow-x: clip;

  &__nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.25rem 2rem 1.25rem 1.8rem;
    background: $white;

    & > * {
      margin-left: 2rem;
    }
  }

  &__logo {
    margin-left: 0;
    margin-right: auto;
    display: none;

    &--sticky {
      display: block;

      img {
        height: 4rem;
      }
    }
  }

  &__basket,
  &__search-rooms {
    margin-top: -0.2rem; // align icon in sticky/default styles
  }

  &__basket {
    position: relative;

    &--count {
      position: absolute;
      min-width: 1.4rem;
      height: 1.5rem;
      left: 1.4rem;
      top: -0.7rem;
      border-radius: 50%;
      color: $white;
      background: $green;
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-align: center;
    }
  }

  &__toggle-menu {
    #nav-close-icon {
      display: none;
    }
  }

  .quicksearch {
    display: flex;
    flex-direction: column-reverse;
  }

  .error {
    position: static !important;
    align-self: center;
    display: inline-block;
    margin: 0.5rem 0;
    padding: 1rem;
    background-color: $red !important;
    color: $white !important;
  }
}

#home {
  .mobile-header {
    &__nav {
      align-items: flex-start;
      padding: 2.2rem 2rem 2.5rem 3rem;
      background: transparent;
    }

    &__logo {
      display: block;
      margin-left: 0;
      margin-right: auto;

      &--sticky {
        display: none;
      }

      img {
        display: block;
        height: 4rem;
      }
    }
  }

  .mobile-header--menu-open,
  .mobile-header--search-open,
  .mobile-header--room-types-open,
  .mobile-header--sticky {
    .mobile-header__nav {
      align-items: center;
      padding: 1.25rem 2rem 1.25rem 1.8rem;
      background: $white;
    }

    .mobile-header__logo {
      display: none;

      &--sticky {
        display: block;

        img {
          height: 4rem;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .mobile-header {
    display: none;
  }
}
