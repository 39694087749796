.my-account-main-panel--booking-hosts {

  &.my-account-main-panel--wide {
    .my-account-filters {
      & > .field {
        flex-basis: 37%;
      }
    }
  }

  .button {
    margin-right: auto;
  }
} 
