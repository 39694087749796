@mixin heading {
  color: $green;
  font-size: 2.6rem;
  line-height: 1.15;
  font-weight: 600;
  text-align: center;

  @media screen and (min-width: $tablet) {
    font-size: 3.6rem;
  }
}
