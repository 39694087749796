.room-types {
  position: fixed;
  width: 100vw;
  height: calc(100vh - #{$mobileHeaderHeight} + 1rem);
  left: 0;
  top: $mobileHeaderHeight;
  background: $veryLightGrey;
  z-index: 10;
  border-top: 1px solid $lightBlueGrey;
  padding: 3rem 2rem;

  &__title {
    margin-bottom: 2.5rem;
    @include heading;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    left: -99rem;
    position: absolute;

    /* accessibility focus state on tabbing */
    &:focus {
      & + label {
        outline: 1px dotted red;
      }
    }
  }

  $optionHeight: calc((100vh - 24rem - #{$mobileHeaderHeight}) / 4);
  //  option height - (vertical padding + icon bottom margin + title height)
  $optionIconHeight: calc(#{$optionHeight} - (5vh + 1.5vh + 1.9vh));
  $optionIconWidth: calc(100vw - 24rem);

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc((100vw - 5rem) / 2);
    height: $optionHeight;
    margin-bottom: 1rem;
    border: 1px solid $pink;
    border-radius: 3px;
    padding: 3vh 2vh 2vh;
    color: $pink;
    background: $lightGreyBrown;
    font-size: 1.9vh;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    .room-types__icon {
      path {
        fill: $pink;
      }
    }

    &:nth-of-type(odd) {
      margin-right: 1rem;
    }

    &--clinical {
      border-color: $darkBlue;
      color: $darkBlue;

      &.room-types__option--active {
        background-color: $darkBlue;
      }

      .room-types__icon {
        path {
          fill: $darkBlue;
        }
      }
    }

    &--active {
      background-color: $pink;
      background-image: none !important;
      color: $white;

      .room-types__icon {
        path {
          fill: $white !important;
        }
      }
    }
  }

  &__icon {
    margin-bottom: 1.5vh;

    svg {
      height: $optionIconHeight;
      width: auto;
      max-width: $optionIconWidth;
    }
  }

  &__link {
    display: none;
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: right;

    a {
      color: $darkGrey;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      margin-left: 0.7rem;
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5rem;

    a {
      color: $darkGrey;
    }

    .button {
      width: auto;
      margin-left: auto;
      border-radius: 0.3rem !important;
    }
  }

  @media (min-width: $tablet) {
    position: absolute;
    top: 6.5rem;
    width: 70rem;
    height: auto;
    border: 1px solid $midGrey;
    border-radius: 3px;
    padding: 2rem 2rem 1rem;
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.3);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 16rem;
      top: -11px;
      width: 0;
      height: 0;
      border-color: transparent transparent $midGrey transparent;
      border-style: solid;
      border-width: 0 10px 10px 10px;
    }

    &::after {
      border-bottom-color: $white;
      top: -10px;
    }

    &__option {
      width: 15.6rem;
      height: 11.4rem;
      margin: 0 1rem 1rem 0;
      padding: 2rem 1rem;
      background-image: linear-gradient(137deg, $white 0%, #ece8e8 100%);

      &:nth-of-type(odd) {
        margin-right: 1rem;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    &__icon {
      margin-bottom: 1rem;

      svg {
        height: 5rem;
        width: auto;
      }
    }

    &__label {
      font-size: 1.3rem;
    }

    &__title,
    &__submit {
      display: none;
    }

    &__link {
      display: block;
    }
  }
}
