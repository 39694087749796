.search-results {
  padding: 2rem;

  @media (min-width: $tablet) {
    padding: 2rem 0;
  }

  &__heading {
    color: $green;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 0 0.7rem;
    padding: 0;
    text-transform: uppercase;

    @media screen and (min-width: $tablet) {
      margin-bottom: 1.2rem;
      font-size: 1.3rem;
    }
  }

  &__inner {
    display: flex;
  }

  &__filters {
    display: none;

    @media (min-width: calc(#{$tablet} + 1px)) {
      color: $darkGrey;
      display: flex;
      margin-left: auto;
      position: relative;
      top: -6px;
    }
  }

  &__filter-item {
    @media (min-width: calc(#{$tablet} + 1px)) {
      margin-left: 1rem;

      &--sortby {
        flex: 0 0 15rem;
        width: 15rem;
      }
    }
  }

  &__info {
    color: $darkBlueGrey;
    line-height: 1;
    margin-bottom: 0;

    span {
      border-right: 1px solid $darkBlueGrey;
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 600;
      padding: 0 0.7rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border: 0;
        padding-right: 0;
      }

      @media screen and (min-width: $tablet) {
        margin-bottom: 1rem;
        font-size: 2.1rem;
      }
    }
  }
}
