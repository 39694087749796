.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.6rem;
  width: 100%;

  &__current-results {
    font-size: inherit;
    display: block;
  }

  &__nav {
    display: flex;

    li {
      margin-right: 0.5rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
    .button {
      background: $lightBlueGrey;
      color: $darkBlueGrey;
      font-size: inherit;
      font-weight: 400;
      padding: 0;
      width: 3rem;
      height: 3rem;
      line-height: 1;
      text-align: center;

      &--active {
        font-weight: 700;
      }
    }
  }

  &__per-page .field--select-list {
    display: flex;
    align-items: center;
    margin: 0;

    label {
      font-size: inherit;
      margin-right: 1rem;
    }

    select {
      font-size: inherit;
      height: 3rem;
      line-height: 2.5rem;
      padding: 0 3rem 0 1.5rem;
    }

    .select-list::after {
      right: 0.75rem;
    }
  }

  @media (max-width: $tablet) {
    font-size: 1.4rem;
    flex-wrap: wrap;

    &__current-results {
      flex-basis: 100%;
    }

    &__nav,
    &__per-page {
      margin-top: 1rem;
    }

    &__nav {
      .button {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &__per-page .field--select-list {
      label {
        margin-right: 0.6rem;
      }

      select {
        padding: 0 0.5rem;
        width: 5.5rem;
        height: 2.4rem;
      }

      .field__input.select-list::after {
        right: 0.5rem;
      }
    }
  }
}
