.favourites {
  margin-bottom: 3rem;

  p {
    font-size: 1.6rem;
  }

  &__title {
    display: flex;
    padding: 0 0 1rem;
  }

  &__title-text {
    color: $green;
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  &__title-action {
    margin-left: auto;
  }

  &__title-action-link {
    background: transparent none;
    border: 0;
    color: $darkGrey;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &::after {
      color: $midGrey;
      content: '\f078';
      display: inline-block;
      font-family: $iconFontFamily;
      font-size: 1.2rem;
      font-weight: 900;
      margin-left: 0.5rem;
      transform: rotate(-90deg);
    }
  }

  &__inner {
    background-color: $lightBlueGrey;
    max-height: 36rem;
    overflow-y: auto;

    &--empty {
      padding: 2rem;
    }
  }
}

.favourite {
  border-bottom: 0.2rem solid $white;
  display: flex;
  height: 10rem;

  &--clinical {
    border-left: 0.6rem solid;
    border-left-color: $darkBlue;
  }

  &--non-clinical {
    border-left: 0.6rem solid;
    border-left-color: $pink;
  }

  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    font-size: 1.2rem;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 12rem;

    img {
      height: 100%;
    }
  }

  &__inner {
    flex: 3;
    padding: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    div {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__details {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    justify-content: space-between;

    .button--small {
      font-size: 1rem;
    }
  }

  &__remove {
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg {
      background: $midGrey;
      border-radius: 2px;
      height: 2rem;
      padding: 0.3rem;
      width: 2rem;
    }
  }
}
