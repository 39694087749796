.favourite-button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  margin: 0;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0;

  @media (min-width: $landscape-mobile) {
    width: 2rem;
    height: 2rem;
  }

  .feature-icon {
    margin: 0;
  }
}
