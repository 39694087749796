.maintenance-header {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 2.5rem 3rem 3rem 4rem;

  &__nav {
    display: flex;
  }

  .header__logo {
    margin-right: 4rem;

    img {
      height: 8rem;
      width: auto;
    }

    &--sticky {
      display: none;
      position: relative;
      top: 0.3rem;
      margin-right: 3rem !important;

      img {
        height: 4rem !important;
      }
    }
  }
}

.maintenance-banner {
  position: relative;
  display: flex;
  align-items: flex-end;
  background-image: url('/images/mobile-banner.jpg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 $outerGutter;
  min-height: 100vh;

  .banner__content {
    margin-bottom: 4rem;
    max-width: 80rem;
    width: 100%;
  }

  .banner__heading {
    clear: both;
    display: inline-block;
    color: $darkBlueGrey;
    font-size: 2.6rem;
    line-height: 1.5;
    font-weight: 600;
    max-width: 60rem;
    margin: 0 0 1rem;
    text-shadow: 0 0 0.4rem white;

    @media (min-width: $small-mobile) {
      line-height: 3.3rem;
      padding-right: 1rem;
    }

    @media (min-width: $medium-mobile) {
      font-size: 2.8rem;
    }

    @media (min-width: $landscape-mobile) {
      font-size: 3.3rem;
    }

    @media (min-width: $desktop) {
      font-size: 4.2rem;
      line-height: 4.5rem;
    }
  }

  &__intro {
    clear: both;
    display: inline-block;
    color: $darkGrey;
    font-size: 1.3rem;
    font-weight: 600 !important;
    line-height: 1.5;
    max-width: 50rem;
    padding: 0;
    margin-bottom: 9rem;

    @media (min-width: $small-mobile) {
      padding-right: 4rem;
    }

    @media (min-width: $medium-mobile) {
      font-size: 1.5rem;
    }

    @media (min-width: $landscape-mobile) {
      font-size: 1.7rem;
    }

    @media (min-width: $desktop) {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding: 0;
    }
  }

  @media (min-width: $landscape-mobile) {
    background-image: url('/images/banner-meeting.jpg');
    min-height: 100vh;
  }

  @media (min-width: $tablet) {
    display: block;
    height: auto;
    min-height: 100vh;

    padding: 22rem $outerGutter 20vw 4rem;

    &::after {
      content: none;
    }

    &__intro {
      margin-bottom: 2rem;
    }
  }
}
