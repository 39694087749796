.basket {
  @media (max-width: $tablet) {
    margin-top: 4.6rem;
  }

  &__pay {
    clear: both;
    float: right;
    margin: 3rem 0;
  }

  &__back-link {
    text-align: right;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 0 2rem;
    margin: 0 0 1rem;

    @media (min-width: $big-mobile) {
      top: 2.5rem;
    }

    @media (min-width: $tablet) {
      padding: 0;
    }

    &::before {
      color: $green;
      content: '\f053';
      display: inline-block;
      font-family: $iconFontFamily;
      font-weight: 900;
      padding-right: 1rem;
    }

    a {
      color: $black;
      float: right;
      font-size: 1.6rem;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__heading {
    margin: 0 2rem 5rem;

    @media (min-width: $tablet) {
      margin: 0 0 5rem;
    }

    h2 {
      font-size: 3.2rem;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1.6rem;
      margin: 1.5rem 0;
    }

    .button {
      a {
        color: $white;
        text-decoration: none;
      }
    }

    a {
      color: $green;
      font-size: 1.8rem;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__booking-header {
    margin-bottom: 1.8rem;
  }

  &__booking_row {
    align-items: flex-end;
    color: #41525f;
    display: flex;
    font-size: 1.4rem;
    margin-left: 1.8rem;
    padding-top: 1.8rem;
    text-transform: uppercase;

    button {
      background-color: $midGrey;
      -webkit-border-radius: 4px 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px 4px;
      border-radius: 4px 4px 4px 4px;
      color: $white;
      font-size: 1rem;
      margin: 0 0.8rem 0 0.8rem;
      text-transform: uppercase;
    }
  }

  &__total-value {
    font-size: 3rem;
    margin-top: 0.8rem;
  }

  &__total {
    font-weight: 100;
    margin-right: 1.8rem;
    a {
      color: $midGrey;
      font-size: 1.1rem;
      text-transform: uppercase;
    }
  }

  &__label {
    font-weight: 600;
    padding-right: 0.8rem;

    &--host,
    &--po,
    &--service,
    &--booker {
      text-transform: uppercase;
    }
  }

  &__booker-name {
    padding-right: 0.8rem;
  }

  &__booker {
    display: flex;
  }

  &__service {
    display: flex;
    padding-left: 0.8rem;
  }

  &__venue {
    font-size: 2.8rem;
    margin-left: 1.8rem;
    padding-top: 1rem;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__booking-items {
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1.8rem;
    margin-top: 2rem;
    padding-top: 1rem;
    td {
      padding-right: 3rem;
    }
  }

  &__po {
    align-items: flex-end;
    display: flex;
  }

  &__bookingHost {
    align-items: flex-end;
    display: flex;
    margin-left: 0.8rem;
  }

  &__left {
    flex: 3 0 0;
  }

  &__right {
    flex: 1 0 0;
    text-align: right;
  }

  &__booking-item-room {
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .basket-footer {
    display: flex;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    &__info {
      flex: 1;
      padding-right: 2rem;
      font-size: 100%;
    }

    &__payment {
      flex: 1;
    }

    &__agree_terms {
      text-align: right;
      margin: 2rem 1rem;

      @media (min-width: $medium-mobile) {
        margin: 2rem;
      }

      input[type='checkbox'] {
        margin-right: 0.5rem;
      }
    }

    &__pay {
      text-align: right;
      margin: 0 1rem;

      @media (min-width: $medium-mobile) {
        margin: 0 2rem;
      }
    }

    &__add-card {
      .instructional-form {
        margin: 2rem 0;
      }

      .instructional-form__form {
        padding: 3rem 2rem 4rem;
      }
    }
  }

  &__code-of-conduct,
  &__disclaimer {
    margin: 4rem 1rem 0rem 1rem;

    @media (min-width: $medium-mobile) {
      margin: 4rem 2rem 0rem 2rem;
    }

    li {
      font-size: 1.8rem;
      line-height: 2.4rem;
      list-style: disc;
      padding-left: 1rem;
      margin-left: 2rem;
    }
  }
}

.basket-confirmation .basket__back-link {
  @media (max-width: $tablet) {
    display: none;
  }
}

.basket-confirmation {
  @media print {
    margin-top: 3rem;

    .booking_confirmation__logo {
      display: block;
      width: 30rem;
    }

    dl {
      display: inline-block !important;
    }

    dd {
      float: left !important;

      &:after {
        border-right: 1px solid #768692;
        content: '';
        height: 1rem;
        margin-right: 1rem;
        padding-right: 1rem;
      }
    }

    dt {
      float: left !important;
      margin-right: 0.5rem;
    }

    .bookings-summary {
      li {
        display: block !important;
      }

      .icon {
        display: inline-block !important;
        padding-right: 1rem;
      }

      svg {
        margin-bottom: -0.2rem;
      }
    }

    .basket-footer {
      float: right !important;

      &__payment {
        padding-top: 3rem !important;
      }
    }

    div,
    tr {
      break-inside: avoid !important;
    }

    .basket__code-of-conduct,
    .basket__disclaimer {
      display: none;
    }
  }
}
