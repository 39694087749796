.users-list {
  margin: 2.1rem auto;
  max-width: 125rem;
  padding: 0 2rem;

  @media (min-width: $tablet) {
    margin-top: 0.9rem;
  }

  &__container {
    overflow-x: auto;
  }

  .action-button {
    width: 6rem;
  }

  &__spinner {
    display: block;
    margin: 0 auto;
  }
}
