.my-account-main-panel-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $darkBlueGrey;
  color: $white;
  width: 100%;

  & > * {
    flex-basis: 50%;
  }

  h2 {
    flex-basis: 60%;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: 1.6;

    span {
      display: block;
      font-weight: normal;
      font-size: 1.4rem;
      text-transform: none;
    }
  }

  .field,
  &__report-actions {
    flex-basis: auto;
  }

  .field {
    margin: 1rem 0 0 auto;
    order: 3;

    &__input.select-list {
      select {
        font-size: 1.4rem;
        line-height: 3.4rem;
        height: 3rem;
        padding: 0 3rem 0 1rem;
      }

      &:hover {
        &::after {
          color: $green;
        }
      }

      &::after {
        right: 1rem;
      }
    }
  }

  &__report-actions {
    margin-top: 1rem;
    order: 2;

    .button:first-child {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 453px) {
    &__report-actions {
      .button:first-child {
        margin-bottom: 0;
      }
    }
  }

  .switch {
    display: flex;
    justify-content: flex-end;
    flex-basis: 40%;
    margin: 0;

    &__label {
      color: inherit;
    }
  }
}

.my-account-main-panel--narrow {
  .my-account-main-panel-header {
    & > * {
      flex-basis: 100%;
    }

    &__report-actions {
      margin-bottom: 2rem;
    }

    .switch,
    .field {
      margin: 0;
      flex-basis: auto;
    }

    .switch {
      margin-left: auto;
      order: 4;
    }
  }
}

.my-account-main-panel--wide {
  .my-account-main-panel-header {
    & > * {
      flex-basis: auto;
      margin: 0;
    }

    .switch {
      margin-left: auto;
    }

    .field {
      order: 2;
      margin: 0 2rem;
    }
  }
}
