.confirm-booking-cancellation {
  @media (min-width: $tablet) {
    width: 50rem;
  }

  &__header {
    background: $red;
    display: flex;
    padding: 1rem;

    @media (min-width: $medium-mobile) {
      padding: 1.5rem 1rem;
    }
  }

  &__header-title {
    color: $white;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  &__alert-icon {
    svg {
      width: 1.3rem;
      height: 1.4rem;
      margin-right: 1rem;
    }
  }

  &__inner {
    background: $lightBlueGrey;
    padding: 0rem 2rem 1rem;

    @media (min-width: $medium-mobile) {
      padding: 2rem 2rem 1rem;
    }

    @media (min-width: $tablet) {
      padding: 2rem 3rem 1rem;
    }
  }

  h2 {
    margin: 0;
    font-size: 2.5rem;
    color: $darkBlueGrey;

    @media (min-width: $medium-mobile) {
      font-size: 2.8rem;
    }
  }

  &__inner-intro-text {
    font-size: 1.4rem;
    line-height: 2.3rem;
    max-width: 35rem;
    margin: 0;
  }

  &__booking-details {
    background: $tableLightGrey;
    padding: 0 0.5rem;

    div {
      display: flex;
      flex-wrap: wrap;
    }

    p {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 2.7rem;
      color: $darkBlueGrey;
      margin: 0;
    }

    h3 {
      margin: 0;
    }
  }

  &__venue {
    a {
      color: initial;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__items-info {
    background: $white;
    max-height: 8rem;
    overflow: auto;

    @media (min-width: $medium-mobile) {
      max-height: 11rem;
    }

    ul {
      display: flex;
      min-width: 40rem;
    }

    li {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 2.7rem;
      color: $darkBlueGrey;
      margin: auto;
    }
  }

  &__break-line {
    border: 1px solid $midBlueGrey;
  }

  &__cancellation-reason {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $darkBlueGrey;
    margin: 0 0 1rem;
  }

  span {
    padding: 0 0.2rem;
  }
}

.confirm-booking-cancellation-modal {
  margin-top: 1rem;

  @media(min-width: 800px) {
    margin-top: 0;
  }
}
