.space-card {
  border-bottom: 0.2rem solid $white;
  background-color: $lightBlueGrey;
  display: flex;
  height: 12rem;

  &--small {
    height: 10rem;
  }

  &--medium {
    height: 12rem;
  }

  a {
    text-decoration: none;
  }

  &--clinical {
    border-left: 0.6rem solid;
    border-left-color: $darkBlue;
  }

  &--non-clinical {
    border-left: 0.6rem solid;
    border-left-color: $pink;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;

    button {
      background: none;
      border: none;
      padding: 0;
    }
  }

  &__venue {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__no_slots {
    color: $red;
  }

  &__slots li {
    display: inline;
    white-space: nowrap;

    * {
      display: inline;
    }

    &:not(:last-child):after {
      content: ', ';
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 12rem;

    img {
      height: 100%;
    }
  }

  &__inner {
    flex: 3;
    padding: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    div {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__details {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    justify-content: space-between;

    .button--small {
      font-size: 1rem;
    }
  }
}
