.client-services {
  border-bottom: 0.1rem solid #e0d3d3;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  &:last-child {
    border-bottom: none;
  }

  &__details {
    li {
      color: $darkBlueGrey;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 2.1rem;
    }
  }

  &--pending {
    color: $red !important;
  }

  &__tooltip {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: sub;
  }
}

.client-services-form {
  .field {
    &__error {
      max-width: 26rem;
    }
  }

  .button {
    width: 100%;
  }
}
