.my-account-box {
  background-color: $lightBlueGrey;
  margin-bottom: 2rem;

  &--no-padding {
    .my-account-box__body {
      padding: 0;
    }
  }

  &__header {
    background-color: $darkBlueGrey;
    padding: 1.5rem;
    display: flex;
    align-items: center;

    @media (min-width: 320rem) {
      border-radius: 0;
    }

    @media (min-width: 76.8rem) {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    button,
    h3 {
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
    }

    h3 {
      margin-bottom: 0;

      @media (min-width: 320px) {
        font-size: 1.4rem;
      }

      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }

    button {
      margin-left: auto;
    }

    .tooltip {
      margin-left: 1rem;
    }
  }

  &__spinner {
    display: block;
    margin: 0 auto;
  }

  &__body {
    padding: 1.5rem;
    max-height: 30rem;
    overflow-y: auto;

    p {
      font-size: 1.6rem;
      line-height: 1.4;
      margin-bottom: 0;
      font-weight: 500;
    }

    .button-group {
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &--booking {
    &__body {
      padding: unset;
    }
  }
  #keyword-search {
    width: 27rem;
  }

  &__footer {
    button {
      width: 100%;
    }
  }
}
