.icon {
  font-size: 2rem;
  margin: 0 0 2rem;

  &--large {
    font-size: 5rem;
  }

  &--xlarge {
    font-size: 7.5rem;
  }

  &--success {
    color: $green;
  }

  &--error {
    color: $red;
  }

  &--centered {
    text-align: center;
  }

  &--inline {
    float: left;
    display: inline-block;
    margin-right: 1rem;
    position: relative;
    top: 0.3rem;
  }
}
