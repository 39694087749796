@mixin servicesList {
  .services-list {
    max-height: 35rem;
    overflow-y: scroll;
    margin-bottom: 1rem;

    .service-container {
      background: $white;
      overflow: visible;
      margin: 0 0 0.1rem 0;

      &--open {
        min-height: 35rem;
      }

      &__header {
        background: $darkBlueGrey;
        color: white;
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 0.4rem solid $darkBlueGrey;

        &--not-unique {
          background: $darkRed;
        }

        &--clinical {
          border-color: $darkBlue;
        }

        &--non-clinical {
          border-color: $pink;
        }

        h4 {
          font-weight: 400;
          line-height: 1.3;
          margin-bottom: 0;
        }

        .header__not-unique-indicator {
          display: inline-block;
          margin-right: 0.4rem;

          @media (min-width: $tablet) {
            cursor: help;
          }
        }

        .header__actions {
          display: flex;
          align-items: center;

          .button {
            margin-right: 0.5rem;
          }

          .display-toggle {
            color: $darkBlueGrey;
            display: inline-block;
            position: relative;
            width: 2rem;
            height: 2rem;
            overflow: hidden;
            text-indent: -99rem;

            &::after {
              color: $white;
              content: '\F078';
              display: inline-block;
              font-family: 'Font Awesome 5 Free', sans-serif;
              font-size: 1.6rem;
              font-weight: 900;
              position: absolute;
              cursor: pointer;
              right: 0;
              top: calc(50% - 1rem);
              text-indent: 0;
            }

            &--collapse::after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__body {
        padding: 1.5rem 1.5rem 0.5rem;

        .service {
          &__control {
            border: 0;
            box-shadow: 0 0 0.2rem $midGrey;
          }

          &__dropdown-indicator {
            svg {
              width: 2.3rem;
              height: 2.3rem;
            }
          }
        }
      }
    }

    & + p {
      margin-bottom: 3rem;
      text-align: right;
    }
  }
}
