.accounts-document {
  width: max-content;
  margin: 0 auto;
  padding: 2rem;
  line-height: 1.3;

  strong {
    font-weight: bold;
  }

  &__header {
    display: flex;

    img {
      width: 15rem;
      margin-bottom: 4rem;
    }

    address {
      margin: 0 0 0 auto;
    }
  }

  &__body {
    address {
      margin-bottom: 2rem;
      align-content: flex-start;
    }

    .internal_ref_service {
      align-content: flex-end;
      margin-top: auto;
      margin-bottom: 1.65rem;

      p {
        font-size: unset;
        margin-bottom: 0.25rem;
      }

      strong {
        padding-right: 2rem;
      }
    }

    .service {
      margin-right: 3rem;
    }

    table {
      font-size: 1.5rem;
      border: 0.1rem solid white;
      border-width: 0.1rem;
      width: 100%;

      td,
      th {
        padding: 0.5rem 1rem;
        border: 0.1rem solid white;
        text-align: center;
      }

      tr {
        background-color: $lightBlueGrey;

        &:nth-of-type(even) {
          background-color: $lightGrey;
        }
      }

      thead tr {
        background-color: $darkBlueGrey;
        color: white;
      }

      tbody {
        tr {
          td:last-child,
          td:nth-last-of-type(2),
          td:nth-last-of-type(4) {
            text-align: right;
          }

          td:nth-last-of-type(5) {
            white-space: nowrap;
          }
        }
      }
    }

    .totals {
      margin-bottom: 8rem;

      table {
        tr {
          background-color: white !important;

          th,
          td {
            text-align: right;

            &:last-of-type {
              width: 7.6%;
            }
          }

          th {
            width: 10rem !important;
          }
        }
      }
    }
  }

  &__meta {
    position: absolute;
    left: 0;
    top: 2rem;
    width: 100%;
    text-align: center;
  }

  &__payment-terms {
    border: 0.1rem solid black;
    margin-bottom: 8rem;
    padding: 2rem;
    position: relative;
    font-size: 1.6rem;

    h3 {
      background-color: white;
      font-weight: bold;
      left: 1rem;
      top: -1rem;
      padding: 0 1rem;
      position: absolute;
    }

    p {
      margin: 0.5rem 0 0;
    }
  }

  &__payments,
  &__refunds {
    margin-bottom: 4rem;

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    ul {
      list-style: disc;

      li {
        margin-left: 2rem;
      }
    }
  }

  &__outstanding-balance {
    margin: 2rem 0 -2rem;
    color: $darkRed;
    font-size: 3rem;
    font-weight: 600;
  }

  &__actions {
    .button--alert {
      margin-left: 2rem;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media print {
    min-width: auto;
    padding: 0;
    color: black;

    font-size: 1.4rem;
    line-height: 1.6;

    p {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    &__meta {
      top: 10mm;
    }

    &__body {
      table {
        font-size: 1.2rem;
        line-height: 1.3;

        thead,
        tbody {
          tr {
            th,
            td {
              padding: 0.5rem 0.3rem 0.3rem !important;
            }
          }
        }

        thead {
          tr {
            th {
              border: none;
              border-bottom: 0.1rem solid black;
              color: black;
            }
          }
        }
      }

      .totals {
        margin-bottom: 4rem;

        table {
          tbody {
            tr {
              th {
                white-space: nowrap;
              }
            }

            &::before {
              content: '';
              display: block;
              page-break-inside: avoid;
            }
          }
        }
      }
    }

    &__payment-terms,
    &__payments,
    &__refunds {
      page-break-inside: avoid;
    }

    &__payment-terms,
    &__payments {
      margin-bottom: 4rem;

      h3 {
        font-size: 1.6rem;
      }
    }

    &__actions {
      display: none;
    }
  }
}
