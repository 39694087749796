.accounts-list {
  padding: 0.1rem 0 0 !important;
  min-height: 12rem;
  background-color: $white;
}

.accounts-document-summary {
  position: relative;
  margin-top: 0.4rem;
  border-left: 0.4rem solid $darkBlueGrey;
  padding: 1.5rem 2rem 1.5rem 1.1rem;
  background-color: $veryLightBlueGrey;

  &--credit-note {
    border-left-color: $darkRed;
    background-color: $lightBlueGrey;
  }

  &__details {
    line-height: 1.4;
    min-height: 7rem;

    h3 {
      text-transform: uppercase;
      font-size: 1.4rem;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h4 {
      font-size: 1.8rem;
      font-weight: 600;
      color: $black;
    }

    h5 {
      font-size: 1.2rem;
    }
  }

  &__total {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    margin: 0;
    text-align: right;
    font-size: 1.1rem;

    strong {
      display: block;
      font-weight: 600;
      font-size: 2.4rem;
    }
  }

  &__actions {
    margin-top: 2rem;
    display: flex;

    & > :last-child {
      margin-left: 1rem;
    }
  }
}

.my-account-main-panel--wide {
  .accounts-document-summary {
    h3 {
      font-size: 1.4rem;
    }

    &__total {
      right: 2rem;
      top: 1.5rem;
      bottom: auto;
      font-size: 1.2rem;
    }

    &__actions {
      & > :last-child {
        margin-left: auto;
      }
    }
  }
}
