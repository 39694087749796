.basket-totals {
  display: flex;

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  justify-content: flex-end;
  margin: 0;

  &__item {
    list-style: none;
    min-width: 40rem;
    margin-left: 0.2rem;

    @media (max-width: $tablet) {
      min-width: 0;
      width: 100%;
    }

    table {
      font-size: 1.3rem;
      width: 100%;

      th,
      td {
        border-bottom: 2px solid white;
        border-collapse: collapse;
        font-weight: 600;
        padding: 1.5rem 2.5rem;
        text-align: left;
        vertical-align: middle;
      }

      thead {
        th,
        td {
          background: $darkBlueGrey;
        }

        tr {
          cursor: pointer;
        }

        th {
          color: $white;

          h4 {
            display: flex;

            span {
              margin-right: 0.5rem;
            }
          }
        }

        td {
          text-align: right;

          input[type='radio'] {
            position: relative;
            top: 0.2rem;
          }
        }
      }

      tbody {
        td {
          text-align: right;
        }
      }
    }

    &--active {
      table thead {
        th,
        td {
          background: $green;
        }
      }
    }
  }

  &__tooltip {
    &--trigger {
      cursor: help;
      position: relative;
      top: 0.1rem;
    }

    &--content {
      .react-tooltip-lite {
        line-height: 1.6;
      }
    }
  }

  &__subtotal {
    background-color: $tableLightGrey;
    margin-bottom: 0.2rem;

    &--overall {
      background-color: $tableDarkGrey;
    }
  }
}
