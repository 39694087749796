.bookings {
  &-list {
    min-height: 12rem;
  }

  &__property_link {
    color: inherit;
    display: block;
    font-size: 2.25rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-decoration: none;

    @media (max-width: $tablet) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &-summary {
    align-self: end;
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;

    margin-top: 2rem;

    &__date {
      margin-left: 1.5rem;
    }

    li {
      display: flex;
      font-weight: 600;
      line-height: 1.8rem;

      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }

    .icon {
      margin-bottom: 0;
    }

    @media (max-width: $landscape-mobile) {
      li {
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
      }

      p:last-child {
        margin-left: 2rem;
      }
    }
  }

  .mini-info-header {
    &__actions {
      display: inline;
      margin-bottom: 1rem;

      button {
        margin-bottom: 0.6rem;
      }
    }
  }

  .my-account-main-panel-footer {
    padding: 1rem;
    margin-top: 1rem;
  }

  .bookings-keyword-search {
    width: 30rem;
  }

  .right-align {
    margin-left: auto;
  }

  &-document {
    border-top: 4px solid $midBlueGrey;
    display: flex;
    flex-direction: column;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    background-color: $lightBlueGrey;
    border-left: unset;
    padding: unset;

    &__description {
      font-size: 1.6rem;
      margin: 0;
    }

    &__header,
    &__indicators {
      @media (min-width: $desktop) {
        justify-content: flex-start;
        align-items: center;
        display: flex;
      }
    }

    &__indicators {
      margin-left: auto;

      .button {
        margin-right: 0;
      }

      .status-indicator {
        margin-left: 1rem;
      }
    }

    &__pay-now {
      text-align: right;
    }

    .mini-info-header {
      align-items: center;
      color: $midBlueGrey;
      display: flex;
      flex-direction: row;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1rem;
      text-transform: uppercase;

      @media (max-width: $tablet) {
        display: grid;
        grid-template-columns: 6.5rem auto;

        &__second-header {
          .button {
            margin-left: auto;
          }
        }

        dt {
          grid-column: 1;
        }

        dd {
          grid-column: 2;
        }

        dt,
        dd {
          margin-bottom: 1rem;
        }
      }

      dt {
        color: $darkBlueGrey;
        margin-right: 0.5rem;

        &:empty {
          margin: 0;
        }
      }

      dd {
        align-items: center;

        @media (min-width: calc(#{$tablet} + 1px)) {
          &:after {
            border-right: 1px solid $midBlueGrey;
            content: '';
            margin-right: 1rem;
            padding-right: 1rem;
          }
        }

        &:last-child {
          &:after {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
          }
        }

        span {
          margin-right: 0.5rem;
        }

        button {
          font-size: 0.8rem;
          height: 1.6rem;
          line-height: 1.6rem;
        }
      }
    }

    &__details {
      line-height: 1.4;
      padding: 2rem;
      position: relative;

      h2 {
        margin: 0;
      }

      h3 {
        margin-bottom: 0.9rem;
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-right: 1rem;
        width: 100%;
        display: flex;

        > div {
          margin-right: 1rem;

          span {
            margin-right: 1rem;
          }
        }
      }

      h4 {
        font-size: 1.8rem;
        font-weight: 600;
        color: $black;
      }

      h5 {
        font-size: 1.2rem;
      }
    }

    &__totals {
      display: flex;
      width: 50%;
    }

    &__total {
      text-align: right;
      font-size: 1.4rem;

      strong {
        display: block;
        font-weight: 600;
        font-size: 2.4rem;

        @media (max-width: $tablet) {
          font-size: 2.2rem;
          padding-bottom: 0.5rem;
        }
      }
    }

    &__total_discount {
      text-align: right;
      font-size: 1.4rem;

      strong {
        display: block;
        font-weight: 600;
        font-size: 2.4rem;

        @media (max-width: $tablet) {
          font-size: 2.2rem;
          padding-bottom: 0.5rem;
        }
      }

      strong:first-child {
        text-decoration: line-through;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
    }

    &__discount {
      text-align: right;
      margin-right: 1.75rem;

      p {
        font-size: 1.4rem;
        margin-bottom: 1.75rem;
      }

      &__hide {
        display: none;
      }
    }

    &__actions {
      display: flex;
      background: #b9c0c5;
      padding: 2rem;
      width: 100%;

      & > :last-child {
        margin-left: auto;
      }

      .button {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__toggle {
      display: flex;
      justify-content: center;
      background: #b9c0c5;
      padding: 0.5rem;
    }

    .mini-info {
      margin-right: 2rem;

      &--indented {
        margin-left: 2rem;
      }
    }

    .venue-link-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .recurring-icon {
      display: inline-block;
      padding: 0 0.5rem;
    }
  }
}

.button--up,
.button--down {
  position: absolute;
  left: 44%;
  text-transform: uppercase;
  color: $darkBlueGrey;
  display: flex;
  align-self: center;

  &::after {
    color: $green;
    display: inline-block;
    font-family: $iconFontFamily;
    font-size: 1.6rem;
    font-weight: 900;
    margin-left: 0.5rem;
    pointer-events: none;
  }
}

.button--up:after {
  content: '\f077';
}

.button--down:after {
  content: '\f078';
}

.booking-item {
  &__cost {
    text-align: right;
  }

  &__checkbox {
    vertical-align: middle;
  }
}

.toggle-all {
  float: right;
  text-transform: uppercase;
}

.inline-block {
  display: inline-block;
  margin: 0;
}

.building-report-spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
