.venue-content-block {
  max-width: 35.6rem;

  &__container {
    background: $lightBlueGrey;
    padding: 5rem 1.5rem;

    @media (min-width: $tablet) {
      padding: 3rem;
    }
  }

  li {
    display: block;
    margin-bottom: 1rem;
    overflow: hidden;
  }

  &--clinical {
    border-top: 0.6rem solid $darkBlue;

    h3 {
      color: $darkBlue;
    }
  }

  &--non-clinical {
    border-top: 0.6rem solid $pink;

    h3 {
      color: $pink;
    }
  }

  &--clinical,
  &--non-clinical {
    span {
      background: #768692;
      border-radius: 50%;
      color: #fff;
      float: left;
      font-size: 1.3rem;
      height: 2.1rem;
      line-height: 2.3rem;
      margin-right: 1.3rem;
      text-align: center;
      width: 2.1rem;
    }

    li {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;
    }    
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 3.3rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &__block {
    padding: 2rem;
    margin-bottom: 1.5rem;

    @media (min-width: $tablet) {
      padding: 0;
    }
  }

  &__text {
    color: $darkGrey;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0;
  }

  &__text-no-icon {
    padding: 0 3.3rem;
  }

  &__icon {
    float: left;
    margin-right: 1.3rem;
    width: 2rem;
  }
}
