.booking-total {
  align-items: baseline;
  display: flex;
  padding: 1rem;

  &__label {
    flex: 1;
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__price {
    font-size: 3.2rem;
    font-weight: 400;
    margin-left: auto;
  }
}
