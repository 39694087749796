.space {
  border-bottom: 2px solid $lighterGrey;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;

  &--error {
    .space__name {
      color: $red;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .button {
      margin-left: auto;
    }
  }

  &__icon {
    margin-right: 0.5rem;
  }

  &__slot {
    display: flex;
  }

  &__slot_error {
    padding-left: 0.5rem;
  }

  &__tooltip {
    margin-left: 0.5rem;

    svg circle {
      fill: $white;
    }
  }

  &__name,
  &__price {
    font-weight: 600;
  }

  &__name {
    color: $darkBlueGrey;
    display: flex;

    > div {
      margin-left: 1rem;
    }
  }

  &__price {
    margin-left: auto;
    color: $darkBlueGrey;
  }

  &__date {
    display: flex;
    font-weight: 600;
    margin-top: 1.5rem;
  }

  &__timeslot {
    margin-top: 1rem;
  }

  &__timeslot--error {
    color: $red;
  }

  &__recurrence {
    background: $tableLightGrey;
    padding: 2rem;
    margin: 2rem -2rem;
    line-height: 2.2rem;

    .field--checkbox {
      margin: 0;
    }
  }
}
