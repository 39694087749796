.modal {
  background: $lightBlueGrey;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 32.4rem;
  box-shadow: 0rem 0rem 1rem rgb(0, 0, 50);
  border-radius: 0.3rem;

  @media (min-width: $tablet) {
    max-height: 100%;
    overflow-y: auto;
  }

  &--small {
    @media (max-width: $big-mobile) {
      width: 32rem;
    }
  }

  &--xsmall {
    width: 32rem;

    @media (min-width: $landscape-mobile) {
      width: 50rem;
    }

    .modal__title {
      background: $lightBlueGrey;
      display: block;
      padding: 3rem 0 1rem 0;

      &--confirm {
        align-items: center;
        background: $darkRed;
        display: flex;
        margin-bottom: 1.5rem;
        padding: 1rem;

        h1 {
          color: $white;
          font-size: 2rem;
          text-transform: uppercase;
          padding-left: 1rem;
          margin: 0;
        }
      }
    }

    .modal__title-text {
      text-align: center;
      line-height: 1.2;
    }

    .modal__inner {
      padding: 0 3rem 1rem;
    }

    .message {
      font-size: 1.6rem;
      margin: 1rem 0 0 0;

      @media (min-width: $landscape-mobile) {
        font-size: 1.8rem;
        margin: 2rem 0 0 0;
      }
    }

    .modal__footer {
      border: none;
    }
  }

  &:focus {
    outline: 0;
  }

  &--no-padding {
    .modal__inner {
      padding: 0;
    }
  }

  &__footer {
    border-top: 1px solid $lightGrey;
    bottom: 0;
    display: flex;
    left: 0;
    margin-bottom: 2rem;
    padding: 2rem 1rem 0;
    right: 0;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    .button {
      display: block;
      width: 100% !important;
    }
  }

  &__footer-item {
    flex: 1;
    margin: 0 2rem;

    @media (max-width: $tablet) {
      margin-top: 1rem;
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  &__close {
    background: transparent none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    text-transform: uppercase;
    top: 1.7rem;
    z-index: 100;

    &:hover {
      .modal__close-icon {
        color: $black;
      }
    }

    &--button {
      display: flex;
      flex-direction: row;

      span {
        background: $darkBlueGrey;
        border-radius: 3px;
        height: 14px;
        justify-content: center;
        margin-left: 0.5rem;
        width: 14px;

        &::before {
          background-color: $white;
          content: '';
          display: block;
          height: 10px;
          left: 6px;
          position: absolute;
          position: relative;
          top: 2px;
          transform: rotate(45deg);
          width: 1px;
        }

        &::after {
          background-color: $white;
          content: '';
          display: block;
          height: 10px;
          left: 6px;
          position: absolute;
          position: relative;
          top: -8px;
          transform: rotate(-45deg);
          width: 1px;
        }
      }
    }

    &--white {
      color: $white;

      span {
        background: $white;
        color: $darkBlueGrey;
      }
    }

    &--transparent {
      span {
        background: transparent;
      }
    }
  }

  &__close-icon {
    color: $darkGrey;
    font-size: 3rem;
  }

  &__inner {
    padding: 2rem 3rem 1rem;
    text-align: center;

    p {
      font-size: 1.6rem;
      margin-bottom: 0;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__title {
    background-color: $lightBlueGrey;
    padding: 5rem 3rem 1rem;
    border-radius: 0.3rem;
  }

  &__title-text {
    color: $darkBlueGrey;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: 2px;
  }

  .internal-ref-text {
    max-width: 50rem;
    padding-bottom: 1rem;
  }
}
