.my-account-main-panel--activities-report {

  &.my-account-main-panel--wide {
    .my-account-filters {
      & > .field {
        flex-basis: 18%;
      }

      .field--text,
      .field--select-list {
        margin-right: 1rem !important;
      }

      .form__field {
        flex-basis: 18%;
        margin-right: 1rem !important;
      }

      .field__input input,
      .button--dropdown,
      .datepicker-field .field--text {
        font-size: 1.4rem;
        letter-spacing: -0.1px;
      }

      .field__input input,
      .button--dropdown {
        padding: 0 1rem;
      }

      .field__input input,
      .button--dropdown,
      .datepicker-field .field__input {
        &::after {
          right: 1rem;
        }
      }

      .datepicker-field {
        flex-basis: 48%;
      }

      &__date-range {
        display: flex;
        flex-basis: 25%;

        .field {
          margin-right: 0 !important;
        }
      }
    }
  }
}
