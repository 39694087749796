@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }

  body {
    padding: 10mm;
  }

  .header,
  .mobile-header,
  .basket__back-link,
  .booking_confirmation__print,
  .footer {
    display: none !important;
  }

  main {
    margin-top: 0 !important;
  }
}
