.scroll-area {
  overflow: hidden;

  &__horizontal {
    overflow-x: auto;
  }

  &__vertical {
    overflow-y: auto;
  }

  &__without-scrollbars {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
