#search-map {
  height: 100%;
  background: #e8edee;
}

.search-map {
  border-top: 2px solid $midGrey;
  margin: 0 0 3px;

  &__container {
    position: relative;
    display: block;
    height: 46.6rem;
    width: 100%;

    .fa-spinner {
      font-size: 3rem;
      position: absolute;
      top: 40%;
      text-align: center;
      width: 100%;
    }
  }

  &__text {
    padding: 1.5rem 0 0 1rem;
  }

  &__venue {
    background-color: $lightGrey;
    font-family: $primaryFontFamily;
    margin: 0;

    @media (min-width: $tablet) {
      display: flex;
      margin: 1.4rem;
      min-height: 12rem;
    }

    &__image-top {
      @media (min-width: $tablet) {
        flex-direction: column;
      }
    }

    &__image-top &-image {
      border-right: none;
    }
  }

  &__venue-image {
    position: relative;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-right: 1px solid $white;
    color: $darkGrey;
    width: 100%;
    height: 12rem;
    font-size: 1.2rem;
    font-weight: 400;
    justify-content: center;
    line-height: 1.7rem;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $tablet) {
      height: auto;
      overflow: hidden;
    }

    .venue__floorplans {
      position: absolute;
      bottom: 1rem;
      right: 0.2rem;
    }
  }

  &__no-image {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__venue-content {
    padding: 1rem;

    @media (min-width: $tablet) {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 1.5rem 2rem;
    }
  }

  &__venue-line {
    display: flex;
    justify-content: space-between;
  }

  &__venue-name {
    color: $darkGrey;
    font: normal 600 1.9rem/2.5rem $primaryFontFamily;
    margin: 0 0 0;
    padding: 0;
    font-size: 1.4rem;

    @media (min-width: $tablet) {
      font-size: 1.9rem;
    }

    a {
      color: #425563;
    }
  }

  &__venue-organisation {
    font-size: 1.3rem;
    padding-top: 0.5rem;

    span {
      font-weight: bold;
    }
  }

  &__venue-distance-container {
    display: flex;
  }

  &__venue-distance-icon {
    width: 2rem;
  }

  &__venue-distance {
    color: $darkGrey;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.7rem;
    margin: 0;
    padding: 0;

    i {
      margin-right: 0.5rem;
    }
  }

  &__venue-space-types {
    margin-top: auto;

    @media (min-width: $tablet) {
      display: flex;
      width: 350px;
    }
  }

  &__venue-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .venue__feature-list {
      margin-top: 0;
    }
  }

  &__space-rooms {
    display: flex;
    align-items: center;
    color: $darkGrey;

    p {
      font-size: 1.2rem;
      padding: 0;
      margin: 0;
    }
  }

  &__space-room-types {
    display: flex;
    align-items: center;

    p {
      font-size: 1.2rem;
      padding: 0.5rem 0;
      margin: 0;
    }
  }

  &__space-quantity {
    padding: 0.5rem 0;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
  }

  &__venue-space-type {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1;
    text-transform: uppercase;
    padding: 0;

    @media (min-width: $tablet) {
      width: 100%;
    }

    &--clinical {
      color: $darkBlue;
    }

    &--non-clinical {
      color: $pink;
    }
  }
}

/* nasty gmaps infowindow classes */
.gm-style {
  .gm-style-iw-c {
    border-radius: 3px;
    padding: 0 !important;
    transform: translate(-50%, -50%);
    width: 25rem !important;
    max-height: none !important;

    @media (min-width: $tablet) {
      width: 56rem !important;
      transform: translate(-50%, -100%);
    }
  }

  .gm-style-iw-d {
    max-height: none !important;
    overflow: unset !important;
  }

  .gm-style-iw-t::after {
    display: none;

    @media (min-width: $tablet) {
      display: initial;
    }
  }
}

.our-properties-map {
  height: 85vh;

  .search-map {
    &__venue-image {
      @media (min-width: $tablet) {
        height: 250px;
      }
    }
  }

  .search-map__space-quantity {
    background-color: $midBlueGrey;
    color: $white;
    height: 2rem;
    width: 2rem;
    padding: 0;
    border-radius: 1rem;
    text-align: center;
  }

  .search-map__venue-name a {
    text-decoration: none;
  }
}
