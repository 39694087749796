.floorplan-viewer-toolbar {
  display: inline-block;
  background-color: $darkGrey;

  button {
    color: $white;
    background: none transparent;
    border: none;
  }
}

.floorplan-viewer {
  position: relative;

  &-toolbar {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    z-index: 10;
    padding-top: 0.2rem;

    button {
      display: block;
    }
  }
}
