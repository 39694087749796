.inner {
  margin: 0 auto;
  max-width: $maxWidth;
  width: 100%;
}

.no-margin {
  margin: 0;
}

.inline-spinner {
  height: 1em;
  padding: 0 1em;
}
