.booking-slots {
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 2rem;
  position: relative;

  %booking-slots__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 0;
    color: $darkGrey;
    cursor: default;
    font-size: 1.8rem;
    font-weight: 600;
    left: 0;
    line-height: 1.3;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    user-select: none;
    z-index: 30;
  }

  &__overlay {
    @extend %booking-slots__overlay;
  }

  &__overlay-horizontal {
    @extend %booking-slots__overlay;
  }

  $booking-slot-spacing: 0.75rem;

  &__wrapper {
    display: flex;
    flex-direction: column;

    .booking-slot {
      margin-bottom: $booking-slot-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrapper-horizontal {
    display: flex;
    flex-direction: row;

    .booking-slot {
      flex: 1;
      margin-right: $booking-slot-spacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.booking-slot {
  &__btn {
    appearance: none;
    background-color: $white;
    border: 0;
    border: 3px solid $green;
    border-radius: 5px;
    color: $green;
    display: flex;
    font-family: $primaryFontFamily;
    font-size: 1.4rem;
    font-weight: 600;
    width: 100%;
    padding: 0.5rem 1rem;
    position: relative;
    text-transform: uppercase;
    justify-content: space-between;

    &--selected {
      background-color: $green;
      border-color: $darkGreen;
      color: $white;
    }

    &--no-times {
      > span {
        width: 100%;
      }
    }
  }

  .service-clash, .time-clash {
    background-color: $red;
    border-color: $darkRed;
  }

  .unavailable {
    background-color: $lightBlueGrey;
    border-color: #d3dee0;
    color: $midBlueGrey;
  }

  &__slot-icon {
    font-size: 1.5rem;
    margin-left: 0.6rem;
    position: relative;
    top: 1px;
  }
}
