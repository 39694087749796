.activities-widget {
  width: 100%;

  tr {
    color: $darkBlueGrey;
    border-bottom: 0.1rem solid #e0d3d3;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.1rem;

    td {
      width: 10rem;

      @media (min-width: $medium-mobile) {
        width: 35%;
      }

      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        width: 8rem;
        padding: 0 1rem;
      }
    }
  }
}
