.venue {
  background-color: $midBlueGrey;
  border-top: 0.3rem solid $darkBlueGrey;
  list-style: none;
  margin: 0 0 0.1rem 0;

  @media (min-width: $landscape-mobile) {
    background-color: $lightBlueGrey;
    border-color: $midBlueGrey;
    margin: 0 0 0.3rem 0;
  }

  &__inner {
    display: flex;
    min-height: 9.5rem;

    @media (min-width: $landscape-mobile) {
      min-height: 13.5rem;
    }
  }

  &__image {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba($white, 0.1);
    color: $darkGrey;
    display: flex;
    flex: 0 0 30%;
    font-size: 1.2rem;
    justify-content: center;
    line-height: 1.7rem;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $landscape-mobile) {
      border-right: 1px solid $white;
      flex: 0 0 18.8rem;
    }
  }

  &__map {
    @media (max-width: $landscape-mobile) {
      display: none;
    }
    align-items: center;
    border-right: 0.1rem solid $white;
    cursor: pointer;
    display: flex;
    flex: 0 0 5rem;
    justify-content: center;

    @media (min-width: $landscape-mobile) {
      flex: 0 0 15.3rem;
    }

    img {
      width: 4rem;

      @media (min-width: $medium-mobile) {
        width: 9rem;
      }

      @media (min-width: $landscape-mobile) {
        width: initial;
      }
    }
  }

  &__info {
    flex: 1;
    padding: 1rem;
    padding-top: 0rem;
    position: relative;

    @media (min-width: $landscape-mobile) {
      padding: 2rem;
      padding-top: 1rem;
    }

    .favourite-button {
      position: absolute;
      right: 1rem;
      top: 1rem;

      @media (min-width: $landscape-mobile) {
        right: 2rem;
        top: 2rem;
      }
    }
  }

  &__name {
    color: $white;
    font: normal 600 1.3rem/1.6rem $primaryFontFamily;
    margin: 0;
    padding: 0;
    width: 19rem;

    @media (min-width: $landscape-mobile) {
      color: $darkBlueGrey;
      font: normal 600 2.1rem/2.7rem $primaryFontFamily;
      width: initial;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__toggle {
    background: none;
    border: 0;
    bottom: 1rem;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 2rem;

    &::after {
      color: #7bbb54;
      content: '\f078';
      display: inline-block;
      font-family: $iconFontFamily;
      font-size: 1rem;
      font-weight: 900;
      margin-left: 0.5rem;
      position: relative;
      top: 0.3rem;
    }

    &--open {
      &::after {
        top: 0;
        transform: rotate(180deg);
      }
    }

    @media (min-width: $landscape-mobile) {
      color: $black;
      line-height: 2.7rem;
      font-size: 1.3rem;

      &::after {
        font-size: 1.5rem;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__organisation {
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    line-height: 1.3rem;

    @media (min-width: $tablet) {
      line-height: 2rem;
    }

    span {
      font-weight: bold;
    }
  }

  &__organisation-row {
    display: flex;
    flex-direction: row;
    font-size: 1.3rem;
    line-height: 1.3rem;

    @media (min-width: $tablet) {
      line-height: 2rem;
    }

    span {
      font-weight: bold;
    }
  }

  &__distance {
    color: $white;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    margin: 0;
    padding: 0;

    @media (min-width: $landscape-mobile) {
      color: $darkGrey;
    }
  }

  &__feature-list {
    display: flex;
    margin-top: auto;
  }

  &__floorplans {
    color: $black;
    text-decoration: none;

    @media (max-width: $tablet) {
      margin-top: -5rem;
    }

    @media (min-width: $tablet) and (max-width: calc(#{$tablet} + 1px)) {
      margin-top: -1rem;
    }
  }
}
