.pager {
  position: relative;
  padding: 1rem;
  text-align: center;
  background: $midBlueGrey;
  color: $white;

  &__info {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
