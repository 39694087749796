.edit-client {
  display: flex;
  flex-direction: column;

  &--address-row {
    display: flex;
    flex-direction: row;
  }
  .field__input {
    margin-left: 1rem;
  }
  &__modal {
    .modal__title-text {
    margin-left: 1rem;
    text-align: left; 
    }
  }
}

