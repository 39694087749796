.privacy-policy {
  p {
    max-width: 75rem;
  }

  li {
    max-width: 75rem;
  }

  span {
    font-weight: bold;
  }

  a {
    color: -webkit-link;
  }
}
