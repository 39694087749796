html {
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  z-index: 1;
  width: 100%;
  color: $darkGrey;
  font-family: $primaryFontFamily;
  font-size: 1.6rem;
}

a {
  color: inherit;
}

b {
  font-weight: bold;
}

h1 {
  font-size: 4.2rem;
  font-weight: 600;
  line-height: 4.5rem;
  margin: 0 0 2rem;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 2rem;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 1rem;
}

p {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0 0 2rem;

  strong {
    font-weight: 600;
  }
}

img {
  height: auto;
}

form {
  display: block;
}

main {
  display: block;
  width: 100%;

  section {
    .section-header,
    .section-content {
      margin: 0.1rem auto;
      max-width: 125rem;

      @media (min-width: $tablet) {
        padding: 0 $outerGutter;
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      margin-top: 5.1rem;
      margin-bottom: 2rem;
      flex-direction: column;

      @media (min-width: $tablet) {
        margin-top: 2.9rem;
      }
    }

    .section-content {
      margin-bottom: 5rem;

      &--static {
        margin-top: 8.1rem;
        margin-bottom: 10rem;
        padding: 0 4rem;
        color: $darkGrey;
        line-height: 1.4;

        @media (min-width: $tablet) {
          margin-top: 6.9rem;
        }

        h1,
        h2 {
          color: $darkBlueGrey;
        }

        p,
        ol,
        ul {
          font-size: 1.8rem;
          line-height: 2.4rem;
          margin: 1rem 0 2rem;

          strong {
            font-weight: 600;
          }
        }

        ol,
        ul {
          margin-left: 2.5rem;

          li {
            margin-bottom: 1rem;
          }
        }

        ul {
          list-style-type: disc;
        }
      }
    }

    &.highlighted {
      background: $veryLightGrey;
      padding-top: 5rem;
      padding-bottom: 5rem;

      .section-content {
        margin-bottom: 0;
      }
    }

    &.static {
    }
  }

  @media (min-width: $tablet) {
    margin-top: 0.9rem;
  }
}
