.floorplans-list {
  &__item {
    border-bottom: 1px solid $tableMidGrey;
    color: $darkGrey;
    font-weight: 600;
    padding: 1.5rem;
    
    &--selected {
      color: $green !important;
    }

    &:hover {
      cursor: pointer;
      color: $midGrey;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
