.my-account-header {
  align-self: flex-start;

  @media (max-width: $tablet) { 
    justify-content: center;
    align-self: center;
    text-align: center;
  }

  h2 {
    color: $darkBlueGrey;
  }

  h3 {
    color: $orange;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__actions {
    position: relative;
    top: -0.5rem;
    display: inline-block;

    .button {
      margin-left: 1rem;

      &--outline-tertiary {
        color: $midBlueGrey;
        background: $lightBlueGrey;
      }
    }
  }
}
