.flash {
  align-items: top;
  border-radius: 1rem;
  color: $white;
  display: flex;
  font-weight: 600;
  margin: 1rem 0;
  padding: 1rem 1.2rem;

  i {
    display: inline-block;
    padding-right: 0.3rem;
  }

  p {
    line-height: 2.5rem;
    margin-bottom: 0;
  }

  button {
    margin: 1rem 0 0 0;
    background-color: $white;
    color: $red;
  }

  &--alert {
    background-color: $red;
  }

  &--warning {
    background-color: $orange;
  }

  &--success {
    background-color: $green;
  }

  &--button {
    .flash__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__link {
    &--upper {
      text-transform: uppercase;
    }
  }

  &__inner {
    width: 100%;

    p + ul {
      margin: 1rem 0;

      li {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (min-width: $tablet) {
    padding-bottom: 0.8rem;

    button {
      margin: 0 0 0 1rem;
    }

    &--button {
      .flash__inner {
        flex-direction: row;
      }
    }
  }
}
