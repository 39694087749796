.login {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 85rem;
  padding: 0 2rem;

  @media (min-width: $tablet) {
    background-position: right center;
    background-size: cover;
    height: 100vh;
  }

  .password-note-field {
    margin-top: 0.5rem;
  }

  &__content {
    background: $veryLightBlueGrey;
    border-radius: 0.3rem;
    box-shadow: 0 0 1rem 0 $midGrey;
    display: block;
    margin: 0 auto;
    padding: 4.2rem 3rem;
    text-align: center;
    width: 46rem;

    @media (min-width: $tablet) {
      padding: 4.2rem;
    }
  }

  &__heading,
  &__mobile-heading {
    color: $green;
    font-size: 2.2rem;
    letter-spacing: 0;
    margin-bottom: 1.5rem;
    text-align: center;

    @media (min-width: $medium-mobile) {
      font-size: 2.6rem;
    }
  }

  &__heading {
    display: none;

    @media (min-width: $tablet) {
      display: block;
    }
  }

  &__error {
    color: $red;
    display: block;
    font: normal 500 1.2rem/1.4 $primaryFontFamily;
    margin: 0 0 1rem;
  }

  &__mobile-heading {
    display: block;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  .field__input {
    input {
      font-size: 1.2rem;
      margin: 0.5rem 0;
      opacity: 0.8;
      padding: 0.7rem 1rem;
    }
  }

  &__links {
    margin-top: 2.5rem;

    p {
      font-size: 1.3rem;
      line-height: 2.2rem;
      margin: 0;
    }

    a {
      color: $darkGrey;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* Reset main element css */
main.login_page {
  max-width: initial;
  padding: 0;
}
