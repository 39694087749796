/* bootstrap button overrides */

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .button:nth-child(n + 1) {
    margin-left: 0.5rem;
  }
}

$button-padding-top: 0.2rem;
$button-padding-side: 2rem;
$button-padding-bottom: 0;
$button-height: 3.8rem;
$button-height-medium: $button-height - 1rem;
$button-height-small: $button-height - 2rem;
$button-border-width: 0.1rem;

.button {
  background-color: $midBlueGrey;
  border: 0;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $primaryFontFamily;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: $button-height;
  padding: $button-padding-top $button-padding-side $button-padding-bottom;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: filter 0.2s;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover {
    filter: brightness(103%);
    text-decoration: none !important;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--primary {
    background-color: $orange;
  }

  &--secondary {
    background-color: $green;
  }

  &--tertiary {
    background-color: $midBlueGrey;
  }

  &--quaternary {
    background-color: $darkBlueGrey;

    &:disabled {
      opacity: 1;
    }
  }

  &--loading {
    background-color: $tableMidGrey;

    span {
      opacity: 0.5;
    }
  }

  &--error {
    background-color: $darkRed;
  }

  .button__spinner {
    height: 26px;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    top: 1rem;
    width: 26px;
    z-index: 10;
  }

  &--alert {
    padding-left: 4rem;
    background: $darkRed url(/images/icons/bookings/alert.svg) no-repeat 1.7rem
      center;
    background-size: 1.6rem 1.6rem;
    text-align: left;

    &.button--small {
      padding-left: 2.4rem;
      padding-right: 1rem;
      background-size: 1.2rem 1.2rem;
      background-position: 0.7rem center;
    }

    @media (max-width: $tablet) {
      &.button--medium {
        padding-left: 3rem;
        background-position: 1rem center;
      }
    }
  }

  &--text {
    background: none;
    border-radius: none;
    color: $midGrey;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0;
    text-transform: none;

    &:not([disabled]) {
      text-decoration: underline !important;
    }
  }

  &--inherit {
    font-size: inherit;
    color: inherit;
    text-decoration: inherit;
  }

  &--fixed-bottom {
    margin-top: auto;
  }

  &--outline {
    background: none transparent;
    border: $button-border-width solid;
    line-height: ($button-height - $button-border-width * 2);
  }

  &--outline-primary {
    border-color: $orange;
  }

  &--outline-secondary {
    border-color: $green;
  }

  &--outline-tertiary {
    border-color: $midBlueGrey;
  }

  &--primary-text {
    color: $orange !important;
  }

  &--secondary-text {
    color: $green !important;
  }

  &--white-text {
    color: $white !important;
  }

  &--small {
    font-size: 1rem;
    height: 2rem !important;
    line-height: $button-height-small !important;
    letter-spacing: 0.02rem;
    padding: $button-padding-top 1.5rem $button-padding-bottom;
  }

  &--medium {
    font-size: 1.26rem;
    line-height: $button-height-medium;

    @media (max-width: $tablet) {
      padding: $button-padding-top 1rem $button-padding-bottom;
      width: auto;
    }
  }

  &--dropdown {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 $midGrey;
    color: $darkGrey;
    font-weight: 400;
    padding-right: 4.6rem;
    text-align: left;
    text-transform: none;

    &::after {
      color: $green;
      content: '\f078';
      display: inline-block;
      font-family: $iconFontFamily;
      font-weight: 900;
      position: absolute;
      right: 1.5rem;
    }
  }

  &--dropdown-without-arrow {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 $midGrey;
    color: $darkGrey;
    font-weight: 400;
    text-align: left;
    text-transform: none;
    display: flex;
    padding: $button-padding-top 1rem $button-padding-bottom;
  }

  &--dropdown-active {
    background-color: $lightGrey;
    color: $black;
  }

  &--center {
    display: block;
    margin: 0 auto;
  }

  &--right {
    float: right;
    padding-right: 2rem;
  }

  &--right-no-padding {
    float: right;
  }

  &--underline {
    text-decoration: underline;
  }

  &--icon {
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg,
    i {
      background: $midBlueGrey;
      border-radius: 2px;
      height: 2rem;
      padding: 0.3rem;
      width: 2rem;
    }

    i {
      padding: 0 0.3rem;
      line-height: 2rem;
      font-size: 1.3rem;
    }
  }
}

#homeQuickSearch {
  @media (max-width: $tablet) {
    .button {
      &--secondary {
        width: 100%;
      }
    }
  }

  @media (min-width: $tablet) {
    .button {
      &--room-types {
        width: 21.5rem;
      }
    }
  }
}

.buttons {
  display: flex;

  &--stacked {
    flex-direction: column;
  }

  &--centered {
    align-items: center;
  }

  &--fixed-bottom {
    margin-top: auto;
  }
}
