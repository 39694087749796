.layout {
  display: flex;

  &--two-col-stacked-left {
    .layout__first {
      flex: 1;
    }

    .layout__last {
      flex: 0 0 35rem;
      margin-top: 2rem;

      @media (min-width: $landscape-mobile) {
        margin-left: 0;
        margin-top: 3.5rem;
      }

      @media (min-width: 868px) {
        margin-left: 2rem;
      }
      &--sticky {
        position: sticky;
        margin-bottom: 5rem;
      }
    }
  }
}

@media (max-width: 867px) {
  .layout {
    flex-direction: column;
  }
}
