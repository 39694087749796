.video-block {
  padding: 0 3rem;
  margin: 0 auto -6.5rem;

  &__wrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.22);
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $tablet) {
    padding: 0 8rem;
    max-width: calc(82.9rem + 2 * 8rem);
  }
}
