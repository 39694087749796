.calendar {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .timeslot-widget__inner {
    background-color: $white;
    border: none !important;
  }

  &__scroll-area {
    overflow-x: hidden !important;
  }

  &__date_header {
    background: $darkBlueGrey;
    color: $white;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    align-items: center;
    margin-right: 2.5rem;

    &--clashes {
      background: $darkRed;
    }

    span {
      display: flex;
    }

    &--right button {
      margin-left: 2rem;
    }
  }
}

.clashes {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  flex-direction: row;
  text-transform: uppercase;

  > div {
    margin-right: 1rem;
  }

  svg {
    width: 15px;
    height: 15px;
  }
}

.calendar__row {
  ._loading_overlay_wrapper {
    margin-right: 2.5rem;

    ._loading_overlay_overlay {
      z-index: 30;
    }
  }
}
.calendar__space {
  border-bottom: 1px solid $darkBlueGrey;
  display: flex;
  flex-direction: row;
  height: 11rem;
  margin-right: 2.5rem;

  &_details {
    background-color: $lightBlueGrey;
    color: $black;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    width: 22.5rem;

    &_line {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    a {
      cursor: pointer;
    }

    h4 {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    h4 a {
      text-decoration: none;
    }

    &--clinical {
      border-left: 0.6rem solid;
      border-left-color: $darkBlue;
    }

    &--non-clinical {
      border-left: 0.6rem solid;
      border-left-color: $pink;
    }
  }
}

.times {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 11rem;
  position: absolute;
}

.time {
  border-right: 0.1rem solid $midBlueGrey;
  border-collapse: collapse;
  display: flex;
  flex: 0 0 2.5rem;
  flex-basis: 5rem;
}

.opening-hours {
  margin-left: 20rem;

  &--sticky {
    background-color: $white;
    left: 0;
    margin-left: 0;
    position: fixed;
    right: 0;
    top: 8rem;
    z-index: 100;

    .hours {
      margin: 2rem auto;
      max-width: 125rem;

      padding-left: 20rem;
      padding-right: 0;

      @media (min-width: $tablet) {
        padding-left: 23rem;
        padding-right: 3rem;
      }

      // This seems to be the point at which the QuickBook is displayed in a
      // separate column on the right.
      @media (min-width: calc(#{$tablet} + 100px)) {
        padding-right: 40rem;
      }
    }
  }

  ul {
    align-items: stretch;
    display: flex;
    flex-direction: row;
  }

  li {
    border: 0.1rem solid transparent;
    align-self: center;
    display: flex;
    flex: 0 0 2.5rem;
    flex-basis: 5rem;
  }
}
