.location {
  &__inner {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 $midGrey;
    text-align: left;

    button {
      background: none transparent;
      border: none;
      padding: 0;
    }
  }

  &__icon {
    color: $green;
    display: inline-block;
    font-weight: 900;
    margin-left: 1rem;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
  }

  &__input {
    background: none transparent;
    border: none;
    color: $darkGrey;
    font-family: $primaryFontFamily;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 4rem;
    padding: 0 3rem 0 2rem;
    width: calc(100% - 5rem);
    box-sizing: inherit !important;

    @media (min-width: $tablet) {
      width: 31.7rem;
    }
  }

  &__search-results {
    position: absolute;
    top: 5rem;
    background: $white;
    border-radius: 0.3rem;
    padding: 0.5rem 0;
    box-shadow: 0 0 0.1rem 0 $midGrey;
    max-height: 19.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
    z-index: 100;
  }

  &__search-result {
    padding: 0.5rem 1rem;
    width: 26.5rem;

    @media (min-width: $big-mobile) {
      min-width: 38.5rem;
    }

    @media (min-width: $tablet) {
      width: 47.5rem;
    }

    &:hover {
      background-color: $lightGrey;
      cursor: pointer;
    }

    &__button {
      border: none;
      color: $darkBlueGrey;
      background-color: transparent;
      font-family: inherit;
      text-align: left;
      width: 100%;
    }

    &__city {
      color: $midGrey;
    }
  }
}

#quickSearch {
  .location__inner {
    border-radius: 0 3px 3px 0;
  }

  .location__input {
    width: 17.5rem;
  }
}
