.my-account-main-panel--bookings {
  overflow: visible !important;
  max-width: 100%;

  .react-datepicker-popper {
    z-index: 1001;
  }

  &.my-account-main-panel--wide {
    .my-account-filters {
      & > .field,
      &__date-range {
        flex-basis: 31%;
      }

      .form__field,
      input,
      .button--dropdown {
        font-size: 1.4rem;
      }

      .form__field,
      .field--buttons {
        flex-basis: 21.2%;
      }

      .field--text,
      .field--select-list,
      .form__field,
      &__group {
        margin-right: 1rem !important;
      }

      &__group {
        flex-basis: calc(42.4% + 1rem);

        .form__field {
          flex-basis: 50%;

          &:last-of-type {
            margin-right: 0 !important;
          }
        }
      }

      &__date-range {
        display: flex;
        margin-right: 1rem;

        .form__field {
          flex-basis: 50%;

          &:last-of-type {
            margin-right: 0 !important;
          }
        }

        .field {
          margin-right: 0 !important;
        }
      }
    }
  }
}
.my-account-main-panel--wide {
  .my-account-main-panel--bookings__reset-filters {
    margin-left: auto;
    margin-right: 2rem !important;
  }
}
