.nearby-venues {
  display: flex;
  flex-direction: column;
  max-width: 125rem;
  margin: 0 auto 5rem;

  @media (max-width: $tablet) {
    padding: 2rem;
  }

  &__header {
    color: $green;
    display: flex;
    font-size: 2rem;
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    h3 {
      margin-bottom: 0;
    }

    button {
      text-decoration: none;
    }
  }

  &__venues {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }
}
