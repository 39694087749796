.filter__increment {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    margin-right: 1rem;
    color: $darkBlueGrey;
    font-weight: normal;
  }

  input,
  button {
    border: 0.1rem solid $midBlueGrey;
    border-radius: 0.3rem;
  }

  input {
    width: 5rem;
    height: 2.5rem;
    margin: 0 1rem;
    border: 0.1rem solid $lightBlueGrey;
    color: $midBlueGrey;
    text-align: center;
  }

  button {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    color: $lightBlueGrey;
    background: $lightBlueGrey;

    &:disabled {
      opacity: 0.4;
    }
    &::after {
      color: $green;
      content: '\f068';
      display: inline-block;
      font-family: 'Font Awesome 5 Free', sans-serif;
      font-size: 1.4rem;
      font-weight: 600;
      position: absolute;
      left: 0.6rem;
      top: 0.5rem;
    }

    &:nth-of-type(2) {
      &::after {
        content: '\f067';
      }
    }
  }
}
