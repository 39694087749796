.space-filters {
  align-items: center;
  background: $veryLightGrey;
  display: flex;
  padding: 1rem;

  &__container {
    display: flex;
    min-width: 65%;
    flex-wrap: wrap;

    @media (min-width: $tablet) {
      flex-wrap: unset;
      margin-right: 2rem;
      border: 1px transparent solid;
    }

    > div {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
    }

    .form__field,
    .field,
    .field--text {
      margin-bottom: 0;
      margin-right: 0;
      position: relative;
    }

    .field__input input {
      width: auto;
      border-radius: 0.3rem 0 0 0.3rem;

      @media (max-width: $tablet) {
        width: 100%;
      }
    }

    .field .button {
      border-radius: 0;
    }

    .form__field:last-child .button {
      border-radius: 0 0.3rem 0.3rem 0;
    }

    .filter-dropdown--date,
    .datepicker-field__popup {
      width: 25rem;
    }

    .filter-dropdown--date {
      .buttons .button {
        margin-left: 0;
      }
    }

    .filter__increment {
      margin-bottom: 1rem;
    }

    .room-types {
      top: 7rem;
    }

    .buttons .button {
      border-radius: 0.3rem !important;
    }
  }

  &__switch {
    display: flex;
    justify-content: flex-end;
  }

  .button.button--text {
    display: none;

    @media (min-width: $tablet) {
      display: initial;
    }
  }
}
