.my-account-main-panel {
  .my-account-filters {
    background-color: $lightBlueGrey;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .field,
    .form__field {
      margin: 0 0 1rem !important;
    }

    .form__field > .field {
      margin: 0 !important;
    }

    &__date-range {
      display: flex;

      .datepicker-field {
        flex-basis: 50%;

        .field {
          margin-bottom: 1rem;
        }

        &:nth-child(1) {
          .field__input input {
            border-radius: 0.3rem 0 0 0.3rem;
          }
        }

        &:nth-child(2) {
          .field__input input {
            border-radius: 0 0.3rem 0.3rem 0;
          }
        }
      }
    }

    &__group {
      display: flex;

      .form__field {
        flex-basis: calc(50% - 0.5rem);
        margin: 0;

        &:first-of-type {
          margin-right: 0.5rem !important;
        }

        &:last-of-type {
          margin-left: 0.5rem !important;
        }
      }
    }

    .button--dropdown {
      height: 4rem;
      line-height: 4rem;
      padding: 0 1.5rem;
      color: #757575;
      font-weight: 500;
      font-size: 1.6rem;
    }

    .field--buttons {
      margin-top: 1rem !important;
      text-align: center;
    }
  }

  &--wide {
    .my-account-filters {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .field--buttons {
        margin: 0 0 1rem 0 !important;
        text-align: right;
      }
    }
  }
}
