.accept-conditions-modal {
  @media (min-width: $tablet) {
    width: 50rem;
  }

  &__header {
    background: $red;
    display: flex;
    padding: 1rem;

    h4 {
      color: $white;
      font-weight: 600;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      i {
        margin-right: 1rem;
      }
    }
  }

  &__inner {
    padding: 2rem 2rem 0;

    h2 {
      color: $darkBlueGrey;
      line-height: 1.2;
    }

    label,
    p {
      color: $darkGrey;
      font-size: 1.4rem;
      line-height: 1.6;
    }

    .button {
      width: 100%;
    }
  }
}
