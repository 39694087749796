.home_page {
  margin-top: 0;
}

#home {
  .header {
    @media screen and (min-width: $tablet) {
      position: absolute;

      &--sticky {
        position: sticky;
      }
    }
  }

  .mobile-header {
    position: absolute;

    &--sticky {
      position: fixed;
    }
  }

  .quicksearch {
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    @media screen and (min-width: $tablet) {
      flex-direction: column;
    }
  }

  .error {
    align-self: center;
    display: inline-block;
    margin: 0.5rem 0;
    padding: 1rem;
    background-color: $white;
    color: $red;
    position: absolute;
    top: -4.5rem;

    @media screen and (min-width: $tablet) {
      align-self: flex-start;
      position: static;
    }
  }
}

.organisations {
  margin: 2rem 0;
  border-bottom: 1px solid $tableMidGrey;

  h1 {
    color: $green;
    font-size: 3.6rem;
    line-height: 1.15;
    font-weight: 600;
    text-align: center;
    margin-top: 4rem;
  }

  &__icons-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 2rem;
  }

  &__icon {
    align-self: center;

    @media (min-width: calc($tablet - 10px)) {
      text-align: center;
      width: 33.3%;
    }

    @media (min-width: calc($small-desktop + 10px)) {
      text-align: center;
      width: auto;
    }

    img {
      width: 13rem;

      @media (min-width: $big-mobile) {
        width: 15rem;
      }

      @media (min-width: $landscape-mobile) {
        width: 17rem;
      }

      @media (min-width: calc($landscape-mobile + 100px)) {
        width: 19rem;
      }

      @media (min-width: calc($tablet - 10px)) {
        width: 20rem;
      }
    }
  }
}
