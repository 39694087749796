@mixin intro {
  color: $darkGrey;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;

  @media screen and (min-width: $tablet) {
    font-size: 2rem;
  }
}
