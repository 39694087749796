.register-banner {
  background-size: cover;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.registration_page {
  padding-top: 5rem;
  position: relative;

  .registration-form {
    margin: 0;

    .field__input {
      z-index: 0;
    }

    @media (min-width: $tablet) {
      margin: 0 0 14rem;
    }

    @include servicesList;

    .service-container--existing {
      .field--checkbox-list {
        overflow-y: scroll;
        max-height: 35rem;

        ul {
          margin: 0;
          padding: 1rem;

          li {
            margin: 0 0 0.5rem 0;
          }
        }
      }

      .field__error {
        color: $red;
        display: block;
        font-size: 1.2rem;
        line-height: 1.4;
        margin-bottom: 1rem;
      }
    }

    .registration-submission-errors {
      color: $red;
      margin: 2rem 0 1rem;

      ul {
        margin-bottom: 0;
      }
    }
  }
}

.registration-confirmation {
  background: $darkBlueGrey;
  box-shadow: none;
  color: white;
  padding: 2rem 6rem;
  text-align: center;
  width: auto;
  margin: 3rem auto;

  @media (min-width: $landscape-mobile) {
    box-shadow: 0 0 1rem $darkGrey;
    width: 50rem;
  }

  a {
    color: inherit;
  }

  h3 {
    font: normal 700 2.8rem/1.4 $primaryFontFamily;
    margin-bottom: 1rem;
  }

  p {
    font: normal 400 1.5rem/1.4 $primaryFontFamily;
  }

  .button--text,
  .button--text:hover {
    text-decoration: underline !important;
  }

  &__email_icon {
    margin-bottom: 2rem;
  }

  &__actions {
    margin: 4rem 0 0;
  }

  &__response-message {
    font-weight: 700;
    color: $green;

    &--error {
      color: $red;
    }
  }

  &--existing {
    box-shadow: none;

    h4 {
      margin-bottom: 0;
    }

    .field--checkbox-list {
      margin-bottom: 0;

      ul {
        box-shadow: none;
      }
    }
  }
}
