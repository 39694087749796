.rooms,
.venues {
  &__slideshow {
    position: relative;
    overflow: hidden;

    .venue__space-capacity {
      position: absolute;
      bottom: 5.9rem;
      right: 20rem;

      @media (min-width: $landscape-mobile) {
        bottom: 4.9rem;
        right: 28rem;
      }

      .feature-icon__additional-text {
        position: absolute;
        bottom: -2.1rem;
        left: 2rem;
        font-size: 2rem;
        color: #425563;

        @media (min-width: $landscape-mobile) {
          bottom: -1.1rem;
        }
      }
    }

    .venue__floorplans {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .slideshow__map-toggle-btn {
      position: absolute;
      right: 7rem;
      bottom: 2.4rem;
    }
  }
}
