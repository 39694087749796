$buttonAndLabelHeight: 2.5rem;

.timeslot-widget {
  display: flex;
  margin-bottom: 2rem;
  position: relative;

  &__inner {
    background-color: $veryLightBlueGrey;
    flex: 1 0 19rem;
  }

  &__vertical &__inner {
    border-left: 1px solid $midBlueGrey;
    border-right: 1px solid $midBlueGrey;
  }

  &__horizontal &__inner {
    border-top: 1px solid $midBlueGrey;
    border-bottom: 1px solid $midBlueGrey;
  }

  &__horizontal &__content {
    height: 6.5rem;
  }

  &__content {
    display: flex;
    position: relative;
    transition: margin 0.2s ease-in-out;
  }

  &__horizontal, &__horizontal &__content {
    flex-direction: row;
  }

  &__vertical, &__vertical &__content {
    flex-direction: column;
  }

  &__button {
    background: $midBlueGrey none;
    border: 0;
    color: $veryLightBlueGrey;
    cursor: pointer;
    flex: 0 0 $buttonAndLabelHeight;
    line-height: $buttonAndLabelHeight;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 20;

    &:hover {
      background-color: $darkBlueGrey;
      color: $white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background: $midBlueGrey none;
        color: $veryLightBlueGrey;
      }
    }

    &--backward {
      border-radius: 3px 3px 0 0;
    }

    &--forward {
      border-radius: 0 0 3px 3px;
    }
  }

  &__time-label {
    align-items: center;
    display: flex;
    flex: 0 0 $buttonAndLabelHeight;
    background-color: $white;
  }

  &__vertical &__time-label {
    border-top: 1px solid #d8d8d8;
    flex-direction: row;

    &--half-hour {
      border-bottom: 1px solid $midBlueGrey;
    }

    &:first-child {
      border-top: 0;
    }
  }

  &__horizontal &__time-label {
    border-left: 1px solid #d8d8d8;
    flex-direction: column;

    &--half-hour {
      border-right: 1px solid $midBlueGrey;
    }

    &:first-child {
      border-left: 0;
    }
  }

  &__time-label-text {
    color: $midBlueGrey;
    font-size: 1rem;
    font-weight: 500;
  }

  &__horizontal &__time-label-text {
    margin-top: 0.7rem;
  }

  &__vertical &__time-label-text {
    margin-left: 0.7rem;
  }

  &__overlay {
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    color: $darkGrey;
    cursor: default;
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
    left: 0;
    line-height: 1.3;
    padding: 2rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    user-select: none;
    z-index: 30;
  }

  &__slot-inner {
    align-items: center;
    border: 1px solid;
    border-radius: 3px;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: none;
      position: absolute;
    }
  }

  &__vertical &__slot-inner::before, &__vertical &__slot-inner::after {
    border-bottom: 1px solid $darkGreen;
    border-top: 1px solid $darkGreen;
    height: 3px;
    width: 1rem;
    left: calc(50% - 0.5rem);
  }

  &__vertical &__slot-inner::before {
    top: 0.4rem;
  }

  &__vertical &__slot-inner::after {
    bottom: 0.4rem;
  }

  &__horizontal &__slot-inner::before, &__horizontal &__slot-inner::after {
    border-left: 1px solid $darkGreen;
    border-right: 1px solid $darkGreen;
    width: 3px;
    height: 1rem;
    top: calc(50% - 0.5rem);
  }

  &__horizontal &__slot-inner::before {
    left: 0.4rem;
  }

  &__horizontal &__slot-inner::after {
    right: 0.4rem;
  }

  &__slot-label {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    user-select: none;
  }

  &__slot-icon {
    font-size: 2rem;
    left: 1.5rem;
    position: absolute;
    top: calc(50% - 11px);
  }

  &__horizontal &__slot {
    padding: 5px 0
  }

  &__slot {
    display: flex !important;
    flex-direction: column;
    height: calc(15rem + 1px);
    left: 4rem !important;
    padding: 2px 0;
    position: absolute;
    right: 1rem;
    top: 5rem;

    &--resizable {
      z-index: 10;
      .timeslot-widget__slot-inner {
        &::before,
        &::after {
          display: inline-block;
        }
      }
    }
  }

  .unavailable {
    .timeslot-widget__slot-inner {
      background-color: $lightBlueGrey;
      border-color: #d3dee0;
      color: $midBlueGrey;
      cursor: not-allowed;
    }
  }

  .available {
    .timeslot-widget__slot-inner {
      background-color: $green;
      border-color: $darkGreen;
      color: $white;
      cursor: default;
    }
  }

  .service-clash, .time-clash {
    .timeslot-widget__slot-inner {
      background-color: $red;
      border-color: $darkRed;
      color: $white;
      cursor: not-allowed;
    }
  }
}
