.add-to-calendar-modal {
  max-width: 55rem;

 .modal {
   &__inner {
     text-align: left;
   }
  }

  &__checkboxes {
    padding-left: 1rem;
    margin-top: 1rem;
  }

  .field.field--checkbox-list ul {
    height: 11rem;
    padding-left: 1rem;
  }

  &__calendar-link {
    border: 2px solid $midGrey;
    padding: 1rem;
    margin-bottom: 1rem !important;
  }
}
