.sub-nav {
  &__links {
    display: flex;

    li {
      font-size: 1.8rem;
      font-weight: 500;
      margin-left: 4rem;
      text-transform: uppercase;

      &:first-child {
        margin-left: 0;
      }

      a {
        color: $midGrey;
        text-decoration: none;
      }
    }

    &--active {
      border-bottom: 3px solid #7bbb54;
      color: $black !important;
    }
  }
}
