.recurrence__status {
  font-size: 1.8rem;
  margin: 2rem 0;
  padding: 0 3rem;
  text-align: center;

  i {
    display: block;
  }
  &--success {
    color: $green;
  }

  &--error {
    color: $darkRed;
  }
}
