.outdated-browser {
  padding: 0;
  display: block;
  height: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/mobile-banner.jpg');
  max-height: 90rem;

  @media (min-width: $landscape-mobile) {
    background-image: url('/images/banner-meeting.jpg');
    max-height: 100rem;
  }

  &__logo {
    padding: 2rem 3rem 0 4rem;
  }

  &__banner {
    padding: 22rem 3rem 16vw 4rem;
  }
}
