.discount {
  background: $pink;
  color: $white;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.4rem;
  padding-right: 0.5rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;

  .discount__icon {
    margin-left: 1rem;
  }

  @media (max-width: $tablet) {
    font-size: 1.1rem;
    line-height: 0.65rem;

    .discount .discount__icon {
      margin-left: 0.5rem;
    }
  }

  @media (max-width: $landscape-mobile) {
    font-size: 0.65rem;
    line-height: 0.65rem;

    .discount__icon {
      margin-left: 0.3rem;
    }
  }

  @media (min-width: $landscape-mobile) and (max-width: 683px) {
    font-size: 0.71rem;
    line-height: 0.1rem;

    .discount__icon {
      margin-left: 0.45rem;
    }
  }

  .discount__button {
    background: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    all: unset;
  }

  .discount__icon:hover {
    cursor: pointer;
  }

  &--small {
    font-size: 1rem;
    line-height: 2rem;
    right: -2.2rem;
    top: 2rem;
    width: 10rem;

    .discount__icon {
      margin-left: 0.25rem;
    }
  }

  &--large {
    font-size: 1.4rem;
    line-height: 3rem;
    right: -4.5rem;
    top: 5rem;
    width: 21.5rem;

    .discount__icon {
      margin-left: 0.45rem;
    }
  }

  &--rotated {
    transform: rotate(45deg);
    z-index: 49;
  }

  &--rounded {
    border-radius: 15px;
    margin-top: 0.5rem;
    padding: 0.5rem 0.75rem 0.5rem 1rem;

    @media (max-width: $landscape-mobile) {
      border-radius: 15px;
      padding: 0.25rem 0.75rem 0.25rem 0.6rem;
      width: 75px;
    }

    @media (max-width: $medium-mobile) {
      width: 85px;
    }

    @media (min-width: $landscape-mobile) and (max-width: 683px) {
      width: 78px;
      border-radius: 15px;
      margin-top: 0.5rem;
      padding: 0.25rem 0.35rem 0.25rem 0.5rem;
    }
  }
}
