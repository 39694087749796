.booking-hosts {
  align-items: flex-start;
  border-bottom: 0.1rem solid #e0d3d3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;

  &:last-child {
    border-bottom: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__email {
    max-width: 23rem;
    word-wrap: break-word;
  }

  &__details {
    li {
      color: $darkGrey;
      display: flex;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 2.1rem;

      &:last-child {
        font-weight: normal;
      }
    }
  }

  &__tooltip {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: sub;
  }
}

.booking-host__modal {
  overflow-y: visible !important;
}
