.select-list {
  background-color: $white;
  display: inline-block;
  position: relative;

  select {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0.3rem;
    box-shadow: 0 0 5px 0 $midGrey;
    color: $darkGrey;
    cursor: pointer;
    font-size: 1.3rem;
    height: 2.7rem;
    line-height: 2.7rem;
    padding: 0 2rem 0 1rem;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  &--disabled {
    opacity: 0.5;

    select {
      cursor: not-allowed;
    }
  }

  &::after {
    color: #979797;
    content: '\f078';
    display: inline-block;
    font-family: $iconFontFamily;
    font-size: 1.2rem;
    font-weight: 900;
    position: absolute;
    right: 0.5rem;
    top: 0.9rem;
    z-index: 9;
  }
}
