.advanced-booking-summary {
  &__title {
    padding: 1rem;
  }

  &__items {
    padding: 1rem;

    li {
      display: flex;
      flex: 1;
    }

    li span {
      margin-right: 1rem;

      &:nth-child {
        margin-right: 0;
      }
    }
  }
}
