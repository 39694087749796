.payment-form {

  fieldset {
    border: none;
    margin: 0 0 2rem 0;
    padding: 0;
    width: 50%;

    @media (max-width: 500px) {
      width: 100%;
    }

    h3 {
      margin: 0 0 2rem;
    }
  }

  &_columns {
    display: flex;
    gap: 3rem;

    @media (max-width: '500px') {
      flex-direction: column;
    }
  }

  label {
    margin-bottom: 1rem;
    display: block;
  }

  &_icons {
    margin: 4rem 0;

    @media (max-width: '500px') {
      margin: 0 0 4rem 0;

      img {
        width: 13%
      }
    }

    p {
      font-size: 1.6rem;
      margin: 0 0 0.5rem;
    }
  }

  .form--actions {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    button {
      margin-right: 1rem;

      @media (max-width: '500px') {
        padding: 0.2rem 1.75rem;
      }
    }
  }
}

.card-icon {
  margin-right: 1rem;
  opacity: 0.5;



  &--selected {
    opacity: 1;
  }
}

.payment-details, .payment-summary {
  padding: 0 2rem;
}

.payment_details {
  display: flex;
  > div {
    width: 10rem;
  }
}

.card-number {
  display: flex;
}

.postcode-country {
  display: flex;

  > div:first-child {
    width: 30%;
    margin-right: 2rem;
  }

  > div:last-child {
    width: 70%;
  }
}

.field--cvc {
  display: flex;
  .field__input {
    width: 10rem;

    @media (max-width: '500px') {
      width: 11rem;
    }
  }

  .field__error {
    width: 10rem;
  }

  .cvc-explainer {
    display: flex;
    align-items: center;
    margin: 1rem 0 0 1rem;

    @media (max-width: '500px') {
      margin: 1rem 0 0 1rem;
    }

    p {
      width: 14rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
}

.billing-details {
  margin-bottom: 3rem;

  p {
    margin: 0 0 0.5rem;
  }
}

