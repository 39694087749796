.users-widget {
  .my-account-box__body {
    max-height: 23rem;
  }
}

.widget-table {
  width: 100%;

  tr {
    color: $darkBlueGrey;
    border-bottom: 0.1rem solid #e0d3d3;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.1rem;

    td {
      width: 10rem;

      @media (min-width: $medium-mobile) {
        width: 13rem;
      }

      &:nth-child(3) {
        width: 5rem;
      }
    }
  }
}

.new-user {
  margin-bottom: 4rem;

  &__service_type_selector {
    flex-direction: row;
    display: flex;
  }

  &__modal {
    max-height: 100vh;
    overflow: auto;

    @media (min-width: $tablet) {
      .modal__close {
        color: $lightGrey;
      }
    }
  }

  .field--radio {
    width: 50%;
  }

  @media (max-width: $tablet) {
    padding: 2rem;
  }
}

@media (max-width: $tablet) {
  .user-modal-header {
    margin-top: 2rem;
  }
}

.user-modal-header {
  display: flex;

  .field--checkbox {
    margin-top: 1rem;
    margin-left: auto;
  }
}
