.booking-items__table {
  width: 100%;
  font-weight: 600;

  tr {
    background-color: $veryLightGrey;

    td.booking-item__checkbox {
      background-color: $tableLightGrey;
    }

    &:nth-child(even) {
      background-color: $lightGrey;

      td.booking-item__checkbox {
        background-color: $tableMidGrey;
      }
    }
  }

  td {
    font-size: 1.3rem;
    padding: 1.5rem;
  }

  &__ref {
    min-width: 10%;
    max-width: 15%;
  }

  &__date {
    min-width: 10%;
    max-width: 15%;
  }

  &__name {
    min-width: 20%;
    max-width: auto;
  }

  &__times {
    min-width: 10%;
    max-width: 15%;
  }

  &__state {
    min-width: auto;
    max-width: 15%;

    .status-indicator {
      display: inline;
    }
  }

  &__cost {
    min-width: 10%;
    max-width: auto;
    text-align: right;
  }

  &__checkbox {
    width: 3%;
  }
}

.booking-item__space,
.booking-item__details {
  display: flex;
  margin-top: 1rem;
}

.booking-item__details {
  justify-content: space-between;
  align-items: end;
}

.booking-item__times {
  margin-left: 2.5rem;
}

.booking-item__cost {
  display: flex;
}

.booking-item__payment_status {
  align-items: baseline;
  display: flex !important;
  justify-content: space-between;
}
