#quickBookStickyPanel {
  border-top: 0.2rem solid $midBlueGrey;

  .sticky-panel__inner {
    padding: 1.8rem 2rem;
  }

  p {
    margin: 0 0.5rem;
    font-size: 1.4rem;
    line-height: 1.7;
    color: $darkBlueGrey;

    strong {
      font-weight: 700;
    }

    .button {
      width: 10rem;
      padding: 0.8rem 2rem 0.6rem;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.2;
      white-space: normal;
    }
  }

  .quick-book-sticky-panel {
    &__info,
    &__rooms,
    &__total {
      line-height: 1.4;
    }

    &__info {
      display: none;
    }

    &__rooms,
    &__total {
      display: block;
      white-space: nowrap;
    }

    &__rooms {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }

    &__total {
      font-size: 1.7rem;
      color: $darkBlueGrey;
      font-weight: 600;
    }
  }

  @media (min-width: $tablet) {
    p {
      .button {
        width: auto;
        white-space: nowrap;
        line-height: 2.2rem;
      }
    }

    .sticky-panel__inner {
      justify-content: flex-end;
      align-items: center;
      max-width: 125rem;
      margin: 0 auto;
    }

    .quick-book-sticky-panel {
      &__info,
      &__rooms,
      &__total {
        font-size: 1.6rem;
      }

      &__info {
        display: block;
        margin-right: auto;
      }

      &__rooms-total {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
      }

      &__rooms,
      &__total {
        display: inline-block;
        margin-right: 0.8rem;
      }

      &__rooms {
        padding-right: 0.8rem;
        border-right: 1.5px solid $darkBlueGrey;
      }

      &__total {
        font-size: 1.6rem;
        color: $darkBlueGrey;
        font-weight: inherit;
      }
    }
  }
}
