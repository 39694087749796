.venue-card {
  flex: 1;

  @media (min-width: $tablet) {
    max-width: 23.75%;
  }

  &__image {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__name {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__location,
  &__distance {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    line-height: 1.6rem;

    i {
      color: $midGrey;
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
  }
}
