.discount-terms-modal {
  width: 100%;
  overflow: auto;

  @media (min-width: $tablet) {
    width: 66rem;
  }

  h2 {
    color: $darkBlueGrey;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: 2px;
  }

  ul {
    list-style: initial;
    text-align: left;
    margin: 0 auto;

    @media (min-width: $tablet) {
      width: 56rem;
    }

    li {
      padding: 1rem 0;

      &:first-child {
        font-weight: bold;
      }
    }
  }

  &__table-heading {
    text-transform: initial !important;
    padding: 1rem 0 !important;
  }

  table {
    font-size: 1.5rem;
    border: 0.1rem solid $midGrey;
    width: 100%;
    background: white;

    @media (max-width: $landscape-mobile) {
      overflow: auto;
      display: block;
      max-height: 18rem;
    }

    thead {
      tr {
        th {
          border: none;
          border-bottom: 0.1rem solid $midGrey;
          padding: 1rem;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem;
        }
      }
    }
  }

  &__tooltip-trigger {
    display: inline-block;
    margin-left: 0.5rem;
  }

  &__tooltip-content {
    ul{
      list-style: initial;
      margin-left: 2rem;

      li {
        span {
          font-weight: bold;
        }
      }
    }
  }
}
