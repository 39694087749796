@keyframes spinning {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

.spinning {
  animation-name: spinning;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon--left-margin {
  margin-left: 0.5rem;
}

.icon--right-margin {
  margin-right: 0.5rem;
}
