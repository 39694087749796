.payment-management {
  @media (min-width: 320px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 35.5rem;
  }

  &__heading {
    background-color: $darkGrey;
    padding: 1.5rem 0;

    @media (min-width: 320px) {
      border-radius: 0;
    }

    @media (min-width: 768px) {
      border-radius: 3px 3px 0 0;
    }

    h3 {
      color: $white;
      display: inline-block;
      padding-left: 2.1rem;
      text-transform: uppercase;
      vertical-align: sub;

      @media (min-width: 320px) {
        font-size: 1.4rem;
      }

      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }

    button {
      color: $white;
      display: inline-block;
    }
  }

  &__tooltip {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: sub;
  }

  .button {
    &--primary {
      font-size: 1rem;
      font-weight: 600;
      line-height: 2.5rem;
      margin-left: 2.5rem;
    }

    &--tertiary {
      border-radius: 0 0 3px 3px;
      width: 100%;

      @media (min-width: 320px) {
        border-radius: unset;
      }

      @media (min-width: 768px) {
        border-radius: 0 0 3px 3px;
      }
    }
  }

  &__inner {
    background-color: $lightBlueGrey;
  }

  &__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem 0 1rem;

    &-title {
      font-weight: 600;
    }
    &-value {
      font-weight: normal;
    }

    &-title,
    &-value {
      flex: 0 50%;
      margin-bottom: 1rem;
    }
  }
}
