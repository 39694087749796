.faq {
  max-width: 125rem;
  margin: 3.1rem auto;
  padding: 0 2rem;

  @media (min-width: $tablet) {
    margin-top: 1.9rem;
    padding: 0 5rem;
  }

  &__intro {
    background: $lightBlueGrey;
    padding: 1rem;

    p {
      font-weight: bold;
    }
  }

  &__topics {
    background: $lightBlueGrey;
    padding: 1rem;
    margin-bottom: 4rem;
  }

  h1 {
    color: $darkBlueGrey;
    font-size: 3.2rem;
  }

  h4 {
    font-weight: bold;
    color: $darkBlueGrey;
    line-height: 3.3rem;
  }

  h3 {
    margin: 1rem 0 0 0;
    background: $darkBlueGrey;
    color: $white;
    padding: 1rem;
    font-size: 1.8rem;
    line-height: 3.3rem;
  }

  p {
    max-width: 82rem;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: $darkBlueGrey;
    white-space: pre-line;
  }

  ul {
    background: $lightBlueGrey;
    &.unstyled{
      background: none;
    }
  }

  &__formatted-list {
    list-style: initial;
    padding-left: 2rem;
    max-width: 82rem;
  }

  ol {
    list-style: decimal;
    padding-left: 2rem;

    li {
      font-weight: bold;
    }
  }

  li {
    font-weight: 500;
    font-size: 1.6rem;
    color: $darkBlueGrey;
    line-height: 3.3rem;
  }

  span {
    margin-top: -5.3rem;
    padding-bottom: 5.3rem;
    display: block;
  }

  .button {
    display: block;
    font-weight: bold;
    font-size: 1.6rem;
    color: $darkBlueGrey;
    text-decoration: none !important;
    width: 100%;
    text-align: left;
    white-space: initial;

    &--link {
      width: initial;
      text-align: initial;
      font-weight: 500;
      padding: 1rem 0 0 0;
    }
  }

  .button--open:after {
    content: '+';
    text-align: right;
    float: right;
  }

  .button--close:after {
    content: '-';
    text-align: right;
    float: right;
  }

  &__inline {
    display: inline;
  }
}
