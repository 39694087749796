.feature-icon {
  display: block;
  height: 1.2rem;
  margin-right: 0.5rem;
  position: relative;
  width: 1.2rem;

  @media (min-width: $landscape-mobile) {
    height: 2.3rem;
    margin-right: 1rem;
    width: 2.3rem;
  }

  &__svg {
    height: 100%;
    width: 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
  }

  &__name {
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
  }

  &--floorplan,
  &--favourite-off,
  &--favourite-on {
    cursor: pointer;
  }

  &--room-capacity {
    width: 2.59rem;

    @media (min-width: $landscape-mobile) {
      width: 4.95rem;
    }

    .feature-icon__additional-text {
      font-size: 0.7rem;
      left: 0.4rem;
      line-height: 1;
      position: absolute;
      top: 0.4rem;

      @media (min-width: $landscape-mobile) {
        font-size: 1.4rem;
        left: 0.65rem;
        line-height: 1;
      }
    }
  }
}
