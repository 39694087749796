.status-indicator {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid;
  display: inline-block;
  font-family: $primaryFontFamily;
  font-size: 1.6rem;
  font-weight: 700;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: $tablet) {
    font-size: 0.8rem;
  }

  &--size-small {
    font-size: 1rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0 1rem;
    border-radius: 1rem;
  }

  &--size-medium {
    font-size: 1.26rem;
    line-height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
  }

  &--size-large {
    font-size: 1.6rem;
    line-height: 4.8rem;
    padding: 0 2rem;
    border-radius: 2.4rem;
  }

  > div, > span {
    display: inline-block;
  }

  &--icon {
    svg {
      position: relative;
      top: 0.3rem;
    }

    &-left > div svg {
      margin-right: 0.5rem;
    }

    &-right > div svg {
      margin-left: 0.5rem;
    }

    // Tooltip icon
    > span svg {
      top: 0.2rem;
      margin-right: 0.5rem;
    }
  }

  &--no-text {
    padding: 0 0.5rem;

    svg {
      margin: 0;
    }
  }

  &--level-none {
    border-color: $darkGrey;
    color: $darkGrey;
  }

  &--level-low {
    color: $darkGreen;
    border-color: $green;

    .status-indicator__tooltip {
      circle {
        fill: $darkGreen;
      }

      text {
        fill: $white;
      }
    }
  }

  &--level-medium {
    color: $darkOrange;
    border-color: $orange;

    .status-indicator__tooltip {
      circle {
        fill: $darkOrange;
      }

      text {
        fill: $white;
      }
    }
  }

  &--level-high {
    color: $darkRed;
    border-color: $red;

    .status-indicator__tooltip {
      circle {
        fill: $darkRed;
      }

      text {
        fill: $white;
      }
    }
  }

  &--tooltip {
    justify-content: space-between;
    padding: 0 1rem;
  }

  &__tooltip {
    left: 0.7rem;
    top: 0.1rem;
  }
}
