.react-tooltip-lite {
  background: $lightBlueGrey;
  box-shadow: 0 0 2px 0 $midGrey;
  color: $darkBlueGrey;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  max-width: 27rem !important;
  padding: 1.5rem !important;
}

.react-tooltip-lite-arrow {
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top-width: 6px !important;
  color: $lightBlueGrey;
  margin-left: 0.5rem;
}

.react-tooltip-lite-arrow::after {
  background-color: $lightBlueGrey;
  border: 1px solid #b7b7b7;
  border-left-width: 0;
  border-top-width: 0;
  content: '';
  height: 10px;
  left: -7px;
  position: absolute;
  top: -10px;
  transform: rotate(45deg);
  width: 10px;
}
