.venues {

  &__switch {
    justify-content: flex-end;
    margin: 10px;
  }
  &__list {
    &--card {
      margin: 1rem 0;
      @media (min-width: $tablet) {
        width: 100%;
        .search-map__venue-image .venue__floorplans {
          left: 0.5rem;
        }
        .venue__map {
          position: absolute;
          left: 0.2rem;
          bottom: 0.2rem;
          img {
            width: 7rem;
          }
        }
      }
      .search-map__space-quantity {
        background-color: $midBlueGrey;
        line-height: 2rem;
        color: $white;
        height: 2rem;
        width: 2rem;
        padding: 0;
        border-radius: 1rem;
        text-align: center;
      }
      .feature-icon {
        @media (max-width: calc(#{$landscape-mobile} - 1px)) {
          width: 2rem;
          height: 2rem;
        }
      }
      .search-map {
        &__venue-image {
          flex-basis: 30rem;

          @media (min-width: $tablet) {
            height: auto;
          }

          .venue__floorplans {
            @media (min-width: $tablet) {
              left: auto;
            }
          }
        }

        &__venue-space-types {
          margin: 1rem 0;
          display: flex;
          justify-content: space-evenly;
        }
        &__venue-footer {
          flex: 0 0 8rem;
          margin-top: auto;
          align-items: flex-end;
          .venue__feature-list {
            flex-wrap: wrap;
            margin-bottom: 1rem;
            width: 13.5rem;
             li {
               margin-top: 0.5rem;
             }
          }
          @media (min-width: $tablet) {
            flex-direction: column-reverse;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }

  .layout__first__top {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;

    @media (max-width: $landscape-mobile) {
      justify-content: flex-end;
    }

    p {
      margin: 10px 0;
      font-size: 14px;
    }

    &--controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
    }
  }

  &__pagination {
    display: flex;
    height: 35px;
    border-radius: 2.5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $lightGrey;
    width: 100%;
    .pagination-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      &__link{
        &--active{
          border: 1px solid $midGrey;
          color: $midGrey;
        }
      }
      li {
        a{
          padding: 5px;
        }
        &:first-child, &:last-child {
          border: none;
          background: none;
          display: none;
        }
        padding: 5px;
        border-radius: 2.5px;
        width: 25px;
        height: 50%;
        margin: 5px;
        text-align: center;
        font-size: 12px;
        color: $midGrey;
        background: $veryLightGrey;
        cursor: pointer;
      }
    }
    &--counter {
      font-size: 15px;
      color: $darkGrey;
      margin: 0 15px;
    }
  }
}
