.content__panel {
  border-radius: 1rem;
  background-color: $veryLightGrey;
  margin: 0 auto;
  padding: 2rem 0 4rem;
  text-align: center;
  width: 60vw;

  p {
    max-width: 60%;
    margin: 0 auto 2rem;
  }

  img,
  div {
    margin: 3rem auto;
  }

  svg {
    height: 100%;
    width: 100%;

    #alert path {
      fill: $red;
    }
  }
}
