.badge {
  background-color: $darkGrey;
  border-radius: 3px;
  color: $white important;
  display: inline-block;
  font-weight: normal;
  margin: 0.4rem 0 0.4rem 1rem;
  min-width: 1.8rem;
  padding: 3px 4px 1px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;

  @media (min-width: $tablet) {
    font-weight: 600;
    margin: 0 1rem;
    padding: 3px 7px;
  }

  &--success {
    background-color: $green;
    color: $white;
  }
}
