.venue-search {
  margin-bottom: 5rem;

  &__list {
    padding: 0 2rem;
  }

  @media screen and (min-width: $tablet) {
    &__list {
      padding: 0;
    }
  }

  &__alternative_venues_message {
    padding: 0 2rem;

    @media (min-width: $tablet) {
      padding: 0;
    }
  }
}

.venue-search--pager {
  .load-more {
    @media (min-width: $landscape-mobile) {
      margin-left: auto;
      display: block;
    }
  }

  .pager__info {
    position: static;
    margin-bottom: 2rem;
    display: block;
    transform: none;

    @media (min-width: $landscape-mobile) {
      position: absolute;
      display: inline;
      transform: translateY(-50%);
    }
  }
}

.venue__spaces {
  background: $white;
  padding-top: 0.3rem;
}
