.activities-report {

  &__container {
    padding: 0;
  }

  &__spinner {
    display: block;
    margin: 0 auto;
  }
}
