.filter-dropdown {
  &--closed > button:after {
    font-family: 'Font Awesome 5 Free';
    content: '\F078';
  }

  &--open {
    > button {
      background-color: $lightBlueGrey;

      &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\F077';
      }
    }
  }

  &__inner {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 $midGrey;
    color: $darkGrey;
    font-weight: 400;
    padding: 2rem;
    position: absolute;
    z-index: 49;
    width: 100%;

    @media (min-width: $landscape-mobile) {
      width: 25rem;
    }

    &--expandable {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 11.8rem;
      width: initial;
      border-radius: unset;
      box-shadow: none;
      overflow-y: auto;
    }

    &--relative {
      position: relative;
      box-shadow: none;
    }

    .field--checkbox-list ul {
      border: none;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
    }
  }

  .buttons {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4rem;

    .button:last-child {
      margin-left: auto;
    }
    @media (min-width: $tablet) {
      margin-top: 0;
    }
  }
}

.list-item {
  padding: 1rem;

  &--selected,
  &:hover {
    font-weight: bold;
  }
}

.field--radio-list {
  input[type="radio"] {
    display: none;
  }

  .field--checkbox-list__item--checked, .field--checkbox-list__item:hover {
    font-weight: bold;
  }
}
