.banner {
  position: relative;
  display: flex;
  align-items: flex-end;
  background-image: url('/images/mobile-banner.jpg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60rem;
  padding: 0 $outerGutter;

  @media (max-width: $big-mobile) {
     height: 70rem;
  }

  &__content {
    margin-bottom: 4rem;
    max-width: 70rem;
    width: 100%;

    @media (max-width: $big-mobile) {
      padding: 1rem 2rem 1rem 3rem;
      position: absolute;
      left: 0;
      top: 26rem;
      width: 90%;
      background-color: #7bbb54;
      box-shadow: 0 0 1rem 0 #858585;
    }
  }

  &__quickSearch {
    margin-bottom: 4rem;
    max-width: 70rem;
    width: 100%;
  }

  &__heading {
    clear: both;
    display: inline-block;
    color: $darkBlueGrey;
    font-size: 2.6rem;
    line-height: 1.5;
    font-weight: 600;
    max-width: 50rem;
    margin: 0 0 1rem;
    text-shadow: 0 0 0.4rem white;

    @media (max-width: $big-mobile) { 
       color: white;
       text-shadow: none;
       font-size: 2.5rem;
    }

    @media (min-width: $small-mobile) {
      line-height: 3.3rem;
    }

    @media (min-width: $medium-mobile) {
      font-size: 2.8rem;
    }

    @media (min-width: $landscape-mobile) {
      font-size: 3.3rem;
    }

    @media (min-width: $desktop) {
      font-size: 4.2rem;
      line-height: 4.5rem;
    }
  }

  &__intro {
    clear: both;
    display: inline-block;
    color: $darkGrey;
    font-size: 1.3rem;
    font-weight: 600 !important;
    line-height: 1.5;
    max-width: 50rem;
    padding: 0;

    @media (max-width: $big-mobile) {
      color: white;
      margin-bottom: 0px;
    }

    @media (min-width: $medium-mobile) {
      font-size: 1.5rem;
    }

    @media (min-width: $landscape-mobile) {
      font-size: 1.7rem;
    }

    @media (min-width: $desktop) {
      font-size: 1.8rem;
      line-height: 2.4rem;
      padding: 0;
    }
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  #quickSearch {
    @media (min-width: $small-mobile) {
      .button {
        &--secondary {
          margin-top: 2.5rem;
          width: 100%;
        }
      }
    }

    @media (min-width: $tablet) {
      .button {
        &--secondary {
          margin-top: auto;
          width: auto;
        }
      }
    }
  }

  &::after {
    content: '\f078';
    position: absolute;
    left: 50%;
    bottom: -1.4rem;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 2.8rem;
    border: 2px solid $green;
    border-radius: 50%;
    color: $green;
    background: $white;
    font-family: 'Font Awesome 5 Free', serif;
    font-weight: 900;
    line-height: 2.5rem;
    text-align: center;
  }

  @media (min-width: $landscape-mobile) {
    background-image: url('/images/banner-meeting.jpg');
  }

  @media (min-width: $tablet) {
    display: block;
    height: auto;
    max-height: 79rem;
    padding: 22rem $outerGutter 20vw 4rem;

    &::after {
      content: none;
    }

    &__intro {
      margin-bottom: 2rem;
    }
  }
}
