.room-types-explainer {
  padding: 4.5rem 3rem;
  text-align: center;

  a {
    text-decoration: none;
  }

  h2 {
    margin-bottom: 0;
    color: $pink;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
  }

  img {
    display: block;
    width: 100%;
    border-top: 5px solid $pink;
    border-radius: 0 0 3px 3px;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.22);
  }

  &__heading {
    margin-bottom: 1rem;
    padding: 0 3rem;
    @include heading;
  }

  &__intro {
    margin-bottom: 3rem;
    padding: 0 1rem;
    @include intro;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $desktop) {
      flex-wrap: nowrap;
    }
  }

  &--clinical {
    h2 {
      color: $darkBlue;
    }

    img {
      border-color: $darkBlue;
    }
  }

  &__item {
    width: calc((100vw - 7.9rem) / 2);
    margin: 0 0 1rem;

    &:nth-of-type(odd) {
      margin-right: 1.9rem;
    }

    &__text {
      margin-bottom: 0;
      font-size: 1.15vw;
      line-height: 1.5;

      &--hidden {
        display: none;
      }
    }

    .button {
      display: none;
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 8rem 8rem 7rem;
    margin: 0 auto;
    max-width: calc(124.8rem + 8rem * 2);

    h2 {
      font-size: 1.75vw;
      margin-bottom: 1vw;
    }

    img {
      margin-bottom: 2.5vw;
    }

    &__item {
      width: calc((100vw - (8rem * 2 + 1.2rem)) / 2);
      margin: 0 0 5.5rem 0;

      &:nth-of-type(odd) {
        margin-right: 1.2rem;
      }

      &__text {
        font-size: 1.33vw;

        &--hidden {
          display: block;
        }
      }

      .button {
        margin-top: 1rem;
        display: inline-block;
        width: auto;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    &__item {
      width: calc((100vw - (8rem * 2 + 1.2rem * 3)) / 4);
      margin-right: 1.2rem;

      &:nth-of-type(4n) {
        margin-right: 0;
      }

      h2 {
        font-size: 1.5vw;
        margin-bottom: 0.67vw;
      }

      img {
        margin-bottom: 2vw;
      }

      &__text {
        font-size: 1.15vw;
      }
    }
  }

  @media screen and (min-width: $large-desktop) {
    h2 {
      font-size: 2.1rem;
      margin-bottom: 1rem;
    }

    img {
      margin-bottom: 3rem;
    }

    &__item {
      width: calc((124rem - 1.2rem * 3) / 4);

      &__text {
        font-size: 1.6rem;
      }
    }
  }
}
