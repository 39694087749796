.form-progress {
  display: flex;
  margin: 20px auto 10px;
  max-width: 40rem;

  &__step {
    height: 5rem;
    padding-top: 2rem;
    position: relative;
    width: 10rem;

    &:not(:first-child)::before,
    &--active:not(:first-child)::before {
      background: $midBlueGrey;
      content: '';
      height: 0.2rem;
      left: -4rem;
      position: absolute;
      top: 1.1rem;
      width: 8rem;
    }

    &--active:not(:first-child)::before {
      background: $green;
    }

    &-no {
      background: $midBlueGrey;
      box-shadow: 0 0 0.3rem $midGrey;
      color: white;
      font: normal 500 1.2rem/2rem $primaryFontFamily;
      height: 2rem;
      left: 50%;
      position: absolute;
      text-align: center;
      top: 0.3rem;
      transform: translate(-50%, 0);
      width: 2rem;
    }

    &-title {
      color: $midBlueGrey;
      display: block;
      font: normal 600 1rem/3rem $primaryFontFamily;
      height: 3rem;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }

    &--active {
      .form-progress__step-no {
        background: $green;
      }

      .form-progress__step-title {
        color: $green;
      }
    }
  }
}
