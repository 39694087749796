.venues {
  &__heading {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10rem;
    width: 100%;

    @media (min-width: $tablet) {
      margin-top: 5rem;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  &__summary {
    background: $darkBlueGrey;
    color: $white;
    padding: 2.1rem;

    @media (min-width: $tablet) {
      padding: 4.5rem 4rem;
    }

    p {
      color: $white;
      font-size: 1.6rem;
      line-height: 2.9rem;
      opacity: 0.9;

      &:first-of-type {

        b {
          font-weight: bold;
        }
      }
      @media (min-width: $tablet) {
        font-size: 2.1rem;
        line-height: 3.1rem;
      }
    }
  }

  .layout--two-col-stacked-left {
    .layout__first {
      max-width: 84rem;
    }

    .layout__last {
      margin-top: 0;
    }
  }

  &__book-now-button {
    display: none;

    @media(min-width: $landscape-mobile) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  .sticky-panel__inner {
    padding: 0;
  }
}
