.ReactPasswordStrength {
  &-strength-desc {
    display: none;
    color: $red;
    font: normal 500 1.2rem/1.4 'Hind', sans-serif;
    margin: 0.2rem 0.2rem 0.3rem;
  }

  &.is-strength-0,
  &.is-strength-1,
  &.is-strength-2,
  &.is-strength-3,
  &.is-strength-4 {
    .ReactPasswordStrength-strength-desc {
      display: block;
    }
  }

  &.is-strength-1 .ReactPasswordStrength-strength-desc {
    color: $darkOrange;
  }
  &.is-strength-2 .ReactPasswordStrength-strength-desc {
    color: $darkGreen;
  }
  &.is-strength-3 .ReactPasswordStrength-strength-desc {
    color: $darkGreen;
  }
  &.is-strength-4 .ReactPasswordStrength-strength-desc {
    color: $darkBlue;
  }
}
