.price {
  align-items: flex-end;
  color: $darkBlueGrey;
  display: flex;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1;

  @media (max-width: $landscape-mobile) {
    font-size: 2.2rem;
  }

  &__label {
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }

  &__amount {
    margin-right: 0.3rem;
  }

  &__help {
    margin-left: 0.3rem;
    padding-bottom: 0.5rem;

    circle {
      fill: $darkBlueGrey !important;
    }
  }

  &--label-bottom {
    display: inline-block;
    padding-right: 2.5rem;
    position: relative;
    text-align: right;

    &.price--price-shown {
      padding-right: 1rem;
    }

    .price__amount {
      margin-right: 0;
    }

    .price__label {
      display: block;
    }

    .price__help {
      position: absolute;
      right: 0.5rem;
      top: -0.3rem;
    }
  }
}
