.slick-slider {
  .slide {
    display: block !important;
    height: 450px;

    @media (min-width: $tablet) {
      min-width: 840px;
    }

    @media (max-width: $tablet) {
      height: 350px;
    }

    @media (max-width: $big-mobile) {
      height: 250px;
    }

    span {
      background-size: cover;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .slick-slide.slick-current {
    opacity: 1 !important;
  }

  .slick {
    &-arrow {
      background: $lightBlueGrey;
      border: none;
      box-shadow: 0 0 0.5rem $darkGrey;
      color: $green;
      font-size: 1.6rem;
      height: 4rem;
      line-height: 1;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 3rem;
      z-index: 10;

      @media (min-width: $landscape-mobile) {
        bottom: 2.4rem;
        top: auto;
        transform: none;
        width: 4rem;
      }
    }

    &-next {
      left: auto;
      right: 0;

      @media (min-width: $landscape-mobile) {
        left: 7rem;
        right: auto;
      }
    }

    &-prev {
      left: 0;

      @media (min-width: $landscape-mobile) {
        left: 2rem;
      }
    }
  }
}
