.error-page {
  display: flex;
  flex-direction: row;
  max-width: 150rem;
  margin: 10rem auto 20rem;
  padding: 0 5rem;

  @media(max-width: $tablet) {
    margin: 5rem auto;
    padding: 0;
    flex-direction: row-reverse;
  }

  &__image {
    width: 30%;

    @media(max-width: $tablet) {
      width: 40%;
      position: absolute;
      top: 30rem;
      right: 2rem;
    }
  }

  &__content {
    margin-left: 5%;
    width: 60%;

    @media(max-width: $tablet) {
      width: 100%
    }

    h1 {
      font-size: 6rem;
      margin-bottom: 4rem;

      @media(max-width: $tablet) {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      display: flex;
      flex-direction: row;
    }

    #email, #inquiry {
      margin-top: 0.25rem;
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;

      path {
        fill: $darkGrey;
      }
    }
  }

  &__links {
    columns: 2;
    line-height: 4rem;
    margin: 3rem 0;

    @media(max-width: $tablet) {
      columns: 1;
    }
  }
}

#lost {
  fill: $green;
}

.error-page-500 {
  .error-page__image {
    @media(max-width: $tablet) {
      display: none;
    }
  }
}
