.about-page {
  .banner {
    height: 28rem;

    @media (min-width: $tablet) {
      height: 60rem;
      background-position: right center;
    }

    &::after {
      display: none;
    }

    &__content {
      max-width: 83rem;
      width: 100%;
      position: absolute;
      bottom: -10%;
      margin: 0;
      background: $green;
      padding: 2rem;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: $tablet) {
        padding: 4rem;
      }

      p {
        color: $white;
        text-align: center;
        display: block;
        margin: 0 auto;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2.8rem;
        max-width: 55rem;
        padding: 0;

        @media (min-width: $tablet) {
          font-size: 2.6rem;
          line-height: 4.3rem;
        }
      }
    }
  }

  .content-block {
    padding: 18rem 1.8rem 4rem;

    @media screen and (min-width: 768px) {
      padding: 18rem 8rem 11rem;
    }

    &--core-aims {
      background: $white;
    }
  }

  &__wrapper {
    padding: 5rem 2rem 5rem 2rem;
    background: linear-gradient(
      to bottom,
      $darkBlueGrey 0%,
      $darkBlueGrey 80%,
      $lightBlueGrey 50%,
      $lightBlueGrey 100%
    );

    @media (min-width: $tablet) {
      padding: 10rem;
    }
  }

  &__explanation {
    color: $white;
    max-width: 83rem;
    margin: auto;
    padding-bottom: 4rem;

    h3 {
      font-weight: 800;
      font-size: 1.5rem;
      line-height: 2rem;
      @media (min-width: $tablet) {
        padding-bottom: 1rem;
        font-size: 2.2rem;
        line-height: 2.2rem;
        margin-top: 4.2rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 2rem;
      text-align: justify;
      @media (min-width: $tablet) {
        font-size: 1.8rem;
        line-height: 3.2rem;
      }
    }

    li {
      list-style: circle;
      font-size: 1rem;
      line-height: 2rem;
      @media (min-width: $tablet) {
        line-height: 2.6rem;
        font-size: 1.8rem;
      }
    }

    b {
      font-size: 1rem;
      @media (min-width: $tablet) {
        font-size: 1.8rem;
      }
    }

    &__roomTypes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      
      li {
        list-style-position: inside
      }
    }
  }

  .video-block {
    &__intro {
      padding: 4rem 0;

      p {
        font-size: 1.4rem;
        line-height: 2.6rem;
        text-align: center;
        color: $darkBlueGrey;

        @media (min-width: $tablet) {
          font-size: 1.8rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}
