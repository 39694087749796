.sticky-panel {
  background: $lightBlueGrey;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 10;

  &--mobile-only {
    @media (min-width: $landscape-mobile) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  &__text {
    color: $darkBlueGrey;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin: 1rem 0 0 0;
    max-width: 16rem;

    @media (min-width: $medium-mobile) {
      font-size: 1.4rem;
    }
  }

  .button {
    font-size: 1.3rem;

    @media (min-width: $medium-mobile) {
      font-size: initial;
    }
  }
}
