.related-spaces {
  &__header {
    display: flex;
    justify-content: space-between;

    > * {
      line-height: 2.4;
      margin: 0;
    }
  }

  &__title {
    color: $green;
  }

  &__inner {
    background-color: $lightBlueGrey;
    max-height: 510px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
