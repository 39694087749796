.clinical-indicator {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;

  &--clinical {
    color: $darkBlue;
  }

  &--non-clinical {
    color: $pink;
  }

  @media screen and (min-width: $tablet) {
    font-size: 1.1rem;
  }
}
