.field {
  display: block;
  margin: 0 0 1.5rem;

  &--no-margin {
    @media (min-width: $tablet) {
      margin: 0 !important;
    }
  }

  &--radio {
    position: relative;

    label {
      cursor: pointer;
      display: block;
      padding: 0 0 0 3rem;
      position: relative;
    }

    input[type='radio'] {
      left: -9999px;
      position: absolute;

      & + label {
        &::after {
          border: 2px solid $midGrey;
          border-radius: 50%;
          content: '';
          height: 19px;
          left: 0;
          position: absolute;
          top: 0;
          width: 19px;
        }
      }

      &:checked {
        & + label {
          &::before {
            background-color: $green;
            border-radius: 50%;
            content: '';
            height: 11px;
            left: 4px;
            position: absolute;
            top: 4px;
            width: 11px;
          }
        }
      }
    }
  }

  &__checkbox {
    display: block;
    font-size: 1.2rem;
    line-height: 1.9rem;
    text-align: left;

    input {
      border-radius: 0.3rem;
      height: 1.5rem;
      margin: 0 0.7rem 0 0;
      outline: none;
      position: relative;
      top: 0.3rem;
      width: 1.5rem;
    }

    label {
      margin-right: 2rem;
    }
  }

  &--checkbox-list {
    ul {
      background: $white;
      border: 0;
      border-radius: 3px;
      box-shadow: 0 0 0.2rem $midGrey;
      color: $darkGrey;
      font: normal 1.6rem/3.5rem $primaryFontFamily;
      max-height: 180px;
      margin-bottom: 0.25rem;
      overflow-y: auto;
      padding: 0.5rem 0;
      width: 100%;
    }

    &__item {
      display: block;

      input {
        margin-right: 0.5rem;
        padding: 0.5rem;
      }

      &--disabled {
        label {
          color: $midGrey;
        }
      }
    }
  }

  &__input {
    position: relative;

    input {
      border: 0;
      border-radius: 3px;
      box-shadow: 0 0 0.2rem $midGrey;
      color: $darkGrey;
      font: normal 500 1.6rem/3.5rem $primaryFontFamily;
      height: 4rem;
      line-height: 4rem;
      overflow: hidden;
      padding: 0 1.5rem;
      width: 100%;
    }

    &--select-list {
      position: relative;
    }

    &.select-list {
      display: block;

      select {
        border-radius: 3px;
        box-shadow: 0 0 0.2rem $midGrey;
        font: normal 500 1.6rem/4rem $primaryFontFamily;
        height: 4rem;
        padding: 0 1.5rem;
      }

      &::after {
        color: $green;
        content: '\f078';
        display: inline-block;
        font-family: $iconFontFamily;
        font-size: 1.6rem;
        font-weight: 900;
        pointer-events: none;
        position: absolute;
        right: 1rem;
      }
    }

    &--placeholder {
      [placeholder] {
        font-family: Hind, Verdana, Tahoma, sans-serif;
        font-weight: 500;
        opacity: 0.8;
        padding-left: 1rem;
      }
    }
  }

  &--text {
    label {
      display: block;
      margin-bottom: 1rem;
    }
  }

  &__loading {
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url(/images/spinner.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px auto;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 12;
  }

  &--dropdown-arrow {
    .field__input {
      &::after {
        color: $green;
        content: '\f078';
        display: inline-block;
        font-family: $iconFontFamily;
        font-weight: 900;
        pointer-events: none;
        position: absolute;
        right: 1rem;
        top: calc(50% - 1rem);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .field__error {
    color: $red;
    display: block;
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .field__note {
    color: $midGrey;
    display: block;
    font-size: 1.2rem;
    line-height: 2;
    padding-left: 0.5rem;
  }

  &--hidden-label {
    .field__label {
      left: -9999px;
      position: absolute;
    }
  }

  &--info-only {
    display: flex;
    margin-bottom: 2rem;

    .field__title {
      flex: 0 0 10rem;

      &::after {
        content: ':';
      }
    }

    .field__desc {
      flex: 1;
    }
  }

  &--has-error {
    input,
    ul {
      border: 1px solid $red;
    }

    .field__error {
      color: $red;
      display: block;
      font: normal 500 1.2rem/1.4 $primaryFontFamily;
      margin: 0.2rem 0.2rem 0.3rem;
    }
  }

  &__wrapper {
    display: flex;

    .field {
      width: 100%;
    }
  }
}

.field-multicol {
  display: flex;
  margin: 0 -0.75rem;

  .field {
    flex: 1;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.password-note-field {
  margin-top: -1rem;

  .field__error {
    font-weight: 500;
    margin-left: 0.2rem;
  }
}

button.link {
  background: none transparent;
  border: none;
  text-decoration: underline;
}

.form--actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;

  .button {
    @media (max-width: $tablet) {
      flex: 1;
    }
  }
}
