.form--quicksearch {
  .form__field,
  .button {
    width: 100%;
  }

  .form__field {
    &:nth-of-type(1) .field {
      margin-bottom: 0;

      button {
        border-radius: 3px 3px 0 0;
      }
    }

    &:nth-of-type(2) .field {
      margin-bottom: 2rem;

      .location__inner {
        border-radius: 0 0 3px 3px;
      }
    }
  }
}

@media (min-width: $tablet) {
  .header .form--quicksearch {
    .form__field {
      margin-right: 0;

      .location__input {
        width: 31.7rem;
      }

      &:nth-of-type(1) .field button {
        border-radius: 3px 0 0 3px;
      }

      &:nth-of-type(2) .field .location__inner {
        border-radius: 0 3px 3px 0;
      }
    }

    & > .button {
      margin-left: 1.5rem;
    }
  }

  .banner .form--quicksearch {
    .field button,
    .field .location__inner {
      border-radius: 3px !important;
    }

    & > .button {
      width: auto;
    }
  }
}
