.my-account-main-panel {
  width: 100%;

  & > * {
    padding: 1.5rem 2rem;
  }

  ._loading_overlay_wrapper {
    padding: 0;
    overflow: auto;
  }

  .button {
    margin-right: 0.5rem;
  }

  @media (max-width: $desktop) {
    margin-bottom: 3rem;
  }
}
