#advanced-booking {
  .section-content {
    max-width: 90vw;
  }

  .field--has-error {
    > div {
      border: 2px solid red;
      border-radius: 3px;
    }
  }

  .opening-hours--sticky .hours {
    margin: 2rem auto;
    max-width: 90vw;
    padding-left: 23rem;
    padding-right: 23vw;
  }
}

.advanced-booking-summary {
  button + button {
    margin-top: 1.5rem;
  }
}
