.rooms {
  padding: 0 1.5rem;

  &__heading {
    display: flex;
    margin: 0.1rem 0 2.7rem;
    max-width: 84rem;
    letter-spacing: 0;

    @media (min-width: $tablet) {
      margin-top: 0;
    }

    h4 {
      font-size: 1.3rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    h1 {
      color: $darkGrey;
      font-size: 2.1rem;
      line-height: 2.1rem;
      margin: 1.5rem 0 1rem 0;

      @media (min-width: $landscape-mobile) {
        font-size: 3.2rem;
      }
    }

    h2 {
      color: $darkGrey;
      font-size: 3rem;
      letter-spacing: 1.88px;
      line-height: 2rem;
      margin-top: 4.5rem;
    }

    a {
      color: $darkGrey;
      font-size: 1.3rem;
      text-decoration: none;

      @media (min-width: $landscape-mobile) {
        font-size: 1.6rem;
      }
    }
  }

  &__price {
    margin-left: auto;
    margin-top: auto;
  }

  &__block {
    max-width: 84rem;

    &--clinical {
      border-top: 4px solid $darkBlue;
    }

    &--non-clinical {
      border-top: 4px solid $pink;
    }
  }

  &__summary {
    background: $midBlueGrey;
    color: $white;
    padding: 2.1rem;

    @media (min-width: $tablet) {
      padding: 4.5rem 4rem;
    }

    p {
      color: $white;
      font-size: 1.6rem;
      line-height: 2.9rem;
      opacity: 0.9;

      @media (min-width: $tablet) {
        font-size: 2.1rem;
        line-height: 3.3rem;
      }
    }
  }

  &__sidebar {
    margin-top: 1rem;

    #quickBook {
      margin-top: 1rem;
    }
  }

  &--clinical {
    color: $darkBlue;
  }

  &--non-clinical {
    color: $pink;
  }

  .layout--two-col-stacked-left .layout__last {
    @media (min-width: $landscape-mobile) {
      margin-top: 0;
    }
  }
}
