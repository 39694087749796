.room-type-banner {
  background-position: center center;
  height: 50rem;
  margin-top: 8rem;

  @media (min-width: $medium-mobile) {
    height: 60rem;
  }

  @media (min-width: $big-mobile) {
    height: 65rem;
  }

  @media (min-width: 800px) {
    margin-top: 0;
  }

  @media (min-width: $desktop) {
    height: 85rem;
  }

  @media (min-width: $large-desktop) {
    height: 95rem;
    background-position: top;
  }

  @media (min-width: $extra-large-desktop) {
    height: 120rem;
  }

  .form {
    .button {
      width: 100%;

      @media (min-width: $tablet) {
        width: auto;
      }
    }
  }
}


#room-types-link {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: $darkBlueGrey;
  text-decoration: none;
  display: inline-block;
  margin-top: 2rem;

  &:hover {
    text-decoration: underline;
  }
}
