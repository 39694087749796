.discounts {
  &__modal {
    margin-right: 0;
    width: 75%;
    max-height: 100vh;
    overflow: auto;

    .modal__title-text {
      text-align: center;
    }
    .modal__inner{
      padding: 3rem;
    }
    table.discounts-table{
      border: 2px solid #ddd;
      width: 100%;
      text-align: center;

      tbody, thead{
        background-color: white;
      }
      thead{
        color: $darkBlueGrey;
        font-weight: 600;
      }
      tr {
        position: relative;
        border-bottom: 0.1rem solid #e0d3d3;
        font-size: 1.3rem;
        &.expired{
          color: $midGrey;
        }
        td, th {
          padding: 1rem 0.5rem;
          &:nth-child(1) {
            width: 30rem;
          }
        }
      }

      .discount__popout--text{
        position: absolute;
        text-align: left;
        padding: 1rem;
        top: -2rem;
        max-width: 20rem;
        max-height: 20rem;
        overflow: scroll;
        border: 2px solid #ddd;
        background-color:  white;
        z-index: 99;
        line-height:1.5rem;
        button{
          float: right;
          margin-top: 1rem;
        }
      }
    }
  }
}
