$orange: #f28939;
$darkOrange: #df7c30;
$yellow: #fcc319;
$green: #7bbb54;
$darkGreen: #5d8c41;
$lightBlue: #4cb6eb;
$darkBlue: #005eb8;
$aqua: #41b6b6;
$pink: #d13c8e;
$red: #b64141;
$darkRed: #8d2a2a;

$veryLightGrey: #f4f4f4;
$lightGrey: #e8e8e8;
$lighterGrey: #bfc2c2;
$midGrey: #858585;
$darkGrey: #3c3c3c;

$veryLightBlueGrey: #eef2f3;
$lightBlueGrey: #e8edee;
$midBlueGrey: #768692;
$darkBlueGrey: #425563;

$tableLightGrey: #d2d7da;
$tableMidGrey: #b9c0c6;
$tableDarkGrey: #96a2ab;

$lightGreyBrown: #efeeea;

$black: #000;
$white: #fff;
