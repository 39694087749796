.org-switcher {
  text-align: left;
  overflow: hidden;
  width: 45rem;

  label {
    padding: 1rem;
  }

  @media (max-width: $big-mobile) {
    width: 100%;
  }

  &__modal {
    max-height: 100vh;
    overflow: auto;
  }

  .button {
    &--down {
      position: unset;
    }

    &--up {
      position: unset;
    }

    &__show {
      margin-left: 2rem;
    }
  }

  .services {
    margin-left: 2rem;
    list-style-type: square;
    padding-left: 2rem;
    margin-top: 0.8rem;
  }

  p {
    margin: 2rem 0 0 0;
    font-size: 1.4rem;
  }
}
