.pdf-document {
  width: 100%;
  overflow: auto;
  border: 0;
  margin-top: 20;
  margin-bottom: 20;
  canvas: {
    margin: auto;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20;
    margin-bottom: 20;
  }

  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: 1;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: 'not-allowed';
  }
}
