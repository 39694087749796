.floorplans__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  button:hover {
    cursor: pointer;
  }
}

.floorplans__header--button {
  display: flex;
  flex: 1;
  justify-content: right;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    padding: 2rem;
  }

  @media (max-width: $tablet) {
    padding: 0 2rem;
  }
}

#floorplans {
  .layout--two-col-stacked-left .layout__last {
    margin-top: 0;
  }
}

button[name='fit-to-viewer'] {
  display: none !important;
}
