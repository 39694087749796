.edit-profile {
  display: flex;
  flex-direction: row;
  padding: 0;
  text-align: left;

  &__role {
    .field__input {
      display: flex;
    }

    .tooltip {
      margin: 1rem
    }
  }

  input:disabled {
    background: $midBlueGrey;
  }

  &__modal {
    margin-right: 0;
    width: 80%;
    max-height: 100vh;
    overflow: auto;

    .modal__title-text {
      text-align: left;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  &__details {
    flex: 50%;
  }

  &__privacy-links {
    margin: 1rem 0rem 1rem 0rem;

    @media (max-width: $tablet) {
      margin: 2rem 0rem 2rem 0rem;
    }
    span {
      margin: 1rem;
    }
  }

  &__marketing-options {
    flex-direction: column;
    margin: 2rem 0rem 2rem 0rem;

    .field--checkbox {
      margin: 0;
    }
  }

  &__marketing {
    flex: 50%;
    margin-left: 3rem;

    @media (max-width: $tablet) {
      margin-left: 0;
    }
  }
}
