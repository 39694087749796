.my-account-main-panel-footer {
  color: $white;
  background-color: $midBlueGrey;
  padding: 1.5rem 1rem;
}

.my-account-main-panel--accounts {
  .my-account-main-panel-footer {
    padding: 1.5rem 1rem 1.5rem 1.4rem;
  }
}
