.my-account {
  .sub-nav {
    align-self: center;
    margin-bottom: 4rem;
  }

  .layout--two-col-stacked-left {
    .layout__first {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: space-between;
      overflow: visible;

      & > * {
        width: 100%;

        &:nth-child(1) {
          order: 3;
        }
        &:nth-child(2) {
          order: 4;
        }
        &:nth-child(3) {
          order: 1;
        }
        &:nth-child(4) {
          order: 2;
        }
      }
    }

    .layout__last {
      margin-top: 0;

      #outstandingPayments {
        display: none;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .sub-nav {
      align-self: flex-end;
      position: relative;
      top: 1.25rem;
      margin-bottom: 0;
    }

    .layout--two-col-stacked-left {
      .layout__first {
        & > * {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            order: 0;
          }
        }

        .my-account-box {
          flex: 0 0 calc(50% - 1rem);
        }

        .my-account-main-panel {
          flex: 1 100%;
        }

        #outstandingPayments {
          display: none;
        }
      }

      .layout__last {
        #outstandingPayments {
          display: block;
        }
      }
    }
  }
}
