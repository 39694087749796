.previous-searches {
  &__title {
    color: $green;
  }

  &__inner {
    background: $lightBlueGrey;
    padding: 1rem 2rem;
  }

  &__list {
    li {
      display: flex;
      justify-content: left;

      &:before {
        content: '';
        position: relative;
        top: 1.6rem;
        margin-right: 1rem;
        // display: block;?
        height: 5px;
        width: 5px;
        background: $green;
      }

      button {
        color: $darkGrey;
        text-align: left;
        white-space: break-spaces;
      }
    }
  }
}
