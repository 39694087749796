.recurrence {
  background: $tableLightGrey;
  font-size: 1.4rem;
  padding: 2rem 4rem;
  line-height: 2.2rem;

  &__quickbook {
    margin: 2rem -2rem 1rem;
  }

  &__default &__row {
    display: flex;
    flex-direction: row;

    > * {
      flex: 1;
      padding: 0 2.5rem;
    }
  }

  .field--checkbox {
    margin: 0;

    label {
      font-size: 1.4rem;
    }
  }

  &__container {
    font-weight: 600;
    padding-top: 2rem;

    .field {
      display: flex;
      width: 9rem;
      margin: 0;
    }

    .field__input {
      width: 100%;
      margin: 0 1rem;
    }

    .field__radio {
      width: auto;
    }
  }

  .recurrence__option {
    &--every-weekday {
      .field,
      label {
        width: auto;
      }
    }

    &--selected-weekday {
      flex-wrap: wrap;

      .recurrence__option__day {
        width: 6.5rem;
      }
    }

    &--end-after {
      flex-wrap: wrap;

      .field__input input {
        width: 7rem;
        padding-right: 0;
      }
    }
  }

  &__option {
    margin-bottom: 1.5rem;
    display: flex;

    label {
      margin: auto;
      width: 9rem;
    }

    span {
      align-items: center;
      display: flex;
    }

    &__day {
      text-transform: capitalize;
      width: 7.5rem;
      margin-top: 1rem;
    }

    &--frequency {
      label {
        width: 13.5rem;
      }
      .field--select-list,
      .select-list {
        width: 100%;
      }
    }

    &--frequency-range {
      .field-input {
        width: 10rem;
      }

      .field--select-list {
        width: 10rem;
      }

      span {
        align-items: center;
        display: flex;
      }
    }

    &--recur-every {
      width: 100%;

      .field {
        width: auto;
      }

      label {
        width: 9rem;
      }

      .field__input--select-list {
        width: 10rem;
      }

      span {
        align-items: center;
        display: flex;
      }
    }

    &--end-by {
      .datepicker-field {
        width: 70%;

        .field {
          width: 100%;
        }
      }
      .field__input {
        width: auto;
      }
    }

    &--end-after {
      .field__input {
        width: auto;

        input {
          width: 10rem;
        }
      }
    }
  }

  &__text {
    margin: 3rem 0 1.5rem 0;
  }

  &__default &__text {
    margin-top: 1rem;
  }

  &__quickbook &__text {
    font-size: 1.7rem;
  }

  &__tooltip {
    top: 0.2rem;
    left: 0.5rem;
  }

  &__max_occurences_error {
    color: $darkRed;
    font-size: 1.5rem;
    display: flex;
    justify-items: center;
    margin: 1.5rem 0 0;

    svg {
      top: 2px;
      margin-right: 0.5rem;
      position: relative;

      g > path {
        fill: $darkRed;
      }

      text {
        fill: $white;
      }
    }
  }
}
