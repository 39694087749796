.contact-details {
  &__wrap {
    margin-bottom: 2rem;
    line-height: 3.3rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $darkGrey;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    float: left;
    margin-right: 1.5rem;
    width: 3rem;
  }

  &__email {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-height: 1.3;
  }
}
