.mobile-space-filters {
  align-items: center;
  background: $lightBlueGrey;
  padding: 1rem 1.5rem;

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .form__field,
    .field,
    .field--text {
      margin-bottom: 0;
      margin-right: 0;
      position: relative;
    }

    .field__input {
      margin-right: 1rem;
      width: 10rem;

      &::after {
        right: 0.7rem;
      }
    }

    .field__input input {
      height: 3rem;
      padding: 0.2rem 1rem 0;
    }

    .datepicker-field__popup {
      width: 25rem;
    }
  }

  .button--dropdown {
    height: 2.8rem;
  }

  .button {
    line-height: 2.8rem;
  }

  .filter-dropdown {
    width: 11rem;

    .buttons {
      margin-top: 0;
    }

    .form__field ~ .buttons {
      margin-top: 1rem;
      justify-content: flex-start;
      align-items: center;

      .button:last-child {
        margin-left: auto;
      }
    }

    .button {
      position: relative;

      #mobile-search-filters {
        position: absolute;
        top: 0;
        right: 0.7rem;
      }
    }

    &__inner--expandable {
      .buttons {
        &:nth-child(2) {
          margin-top: 1rem;
        }
      }

      .button.button--text {
        &:nth-child(2) {
          font-weight: normal;
        }
      }

      .button--dropdown {
        color: $darkBlueGrey;
        font-weight: 600;
        box-shadow: none;
        padding: 0;

        &::after {
          content: '\f054';
          top: -0.1rem;
        }
      }

      .filter-dropdown--open > .button {
        background-color: transparent;

        &::after {
          content: '\f077';
        }
      }

      .form__field {
        padding: 0 0 1rem;

        .button--dropdown::after {
          left: 7rem;
          right: unset;
        }
      }
    }
  }

  .filter-dropdown__inner {
    padding: 2rem 2rem 0;

    & .filter-dropdown__inner {
      padding: 0.5rem 0 0 0.5rem;
      width: calc(100vw - 2.5rem);
    }
  }

  .field--checkbox-list ul {
    width: 100%;
    height: auto;
    max-height: 21rem;
    overflow: scroll;
    margin-bottom: 1rem;
    color: $darkBlueGrey;
    line-height: 1.4;

    li {
      margin: 0.5rem 0 1rem;
    }
  }
  #mobile-search-filters {
    svg {
      vertical-align: middle !important;
      margin-left: 1rem;
    }
  }

  &__switch {
    margin-left: auto;
  }

  .filter-dropdown--capacity {
    .filter-dropdown__inner {
      position: relative;
      padding: 1rem 0.5rem 0;
      box-shadow: none;

      .filter__increment {
        margin-bottom: 1rem;
      }
    }
  }
}
