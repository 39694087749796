.footer {
  position: relative;
  z-index: 1;
  padding: 5rem 0 3.5rem;
  background: $green;
  color: $white;

  &__list:first-child ul li:last-child {
    line-height: 1.4;
  }

  &__links,
  &__bottom {
    padding: 0 2rem;
  }

  hr {
    height: 0.1rem;
    margin: 0 0 3rem;
    border: none;
    background: $white;
    opacity: 0.4;
  }

  ul {
    margin-bottom: 5.5rem;

    li {
      margin-bottom: 1.5rem;

      a {
        color: white;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    width: calc((100vw - 5rem) / 2);

    &:nth-of-type(odd) {
      margin-right: 1rem;
    }

    &:last-child {
      max-width: 26rem;
      margin-left: 0.1px;
      width: 100%;
    }

    img {
      padding-right: 1rem;
      vertical-align: middle;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.1rem;
    margin-bottom: 2rem;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: inline-block;
      margin-left: 1rem;

      img {
        display: block;
      }
    }
  }

  &__bottom p {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }

  &__copyright {
    margin-bottom: 0;

    span {
      display: block;

      &::before {
        content: '';
      }
    }
  }

  @media screen and (min-width: $medium-mobile) {
    padding: 5rem 0 3.5rem;

    &__links,
    &__bottom {
      padding: 0 3rem;
    }

    span {
      display: inline;

      &::before {
        content: ' - ';
      }
    }

    &__list {
      width: calc((100vw - 8rem) / 2);

      &:nth-of-type(odd) {
        margin-right: 2rem;
      }
    }
  }

  @media screen and (min-width: $medium-mobile + 27px) {
    &__list {
      &:first-child ul li:last-child {
        line-height: inherit;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 5.5rem 0 3.5rem;

    &__links,
    &__bottom {
      margin: 0 auto;
      padding: 0 8rem;
      max-width: calc(93.2rem + 8rem * 2);
    }

    &__list {
      width: 25%;
    }

    &__bottom {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
    }

    &__social {
      justify-content: flex-start;
      margin: 0 0 0 auto;
    }
  }

  @media screen and (min-width: $small-desktop) {
    &__list {
      width: 21%;
    }
  }
}
