.mobile-header {
  &--menu-closed {
    .mobile-menu {
      left: 100%;
    }
  }

  &--menu-open {
    .mobile-menu {
      left: 0;
    }
  }

  .mobile-menu {
    position: fixed;
    top: 6.5rem;
    width: 100vw;
    height: 100vh;
    padding: 5rem 5rem 0;
    transition: 0.5s;
    background: $darkBlueGrey;
    text-align: center;

    &__item {
      position: relative;
      margin-bottom: 1.5rem;

      &:nth-child(-n + 2) {
        padding: 0 0 1.5rem;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          width: 8rem;
          height: 0.1rem;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      &:nth-child(3) {
        margin-bottom: 5rem;
      }

      &:nth-child(4) {
        margin-bottom: 3rem;
      }

      a {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        font-size: 2rem;
        font-weight: 500;

        &.button {
          font-size: inherit;
        }
      }
    }
  }
}
