.testimonials-slideshow {
  overflow: hidden;
  padding: 6rem 3rem 4rem;
  position: relative;

  @media (min-width: $tablet) {
    padding: 10.5rem 3rem 11.5rem;
  }

  h1 {
    @include heading;
    margin-bottom: 4rem;

    @media (min-width: $tablet) {
      font-size: 3.6rem;
      margin-bottom: 6rem;
    }
  }

  .slick-slider {
    display: block;
    margin: 0 auto;
    max-width: 125rem;

    @media (min-width: $desktop) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 9%;
        width: calc((100vw - 125rem) / 2 + 6rem);
        background: $lightBlueGrey;
      }

      &::before {
        left: 100%;
      }

      &::after {
        right: 100%;
      }
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    display: flex !important;
    height: inherit !important;
  }

  .slick-slide > div {
    display: flex !important;
    height: inherit !important;
  }

  .slick-list {
    margin: 0;

    @media (min-width: $tablet) {
      margin: 0 7rem;
    }

    .text-slide {
      position: relative;
      height: inherit !important;
      background: $darkBlueGrey;
      border-radius: 0.4rem;
      padding: 7rem 2rem 4rem;

      &::before,
      &::after {
        position: absolute;
        color: $lightBlueGrey;
        content: open-quote;
        display: block;
        font-family: Arial, Verdana, Tahoma, sans-serif;
        font-size: 10rem;
        font-weight: bold;
        height: 2rem;

        @media (min-width: $desktop) {
          font-size: 15rem;
          height: 7rem;
        }
      }

      &::before {
        left: 2.3rem;
        top: 1.3rem;
      }

      &::after {
        right: 2.3rem;
        bottom: 5.3rem;
        content: close-quote;
      }
    }

    .text-wrap {
      padding: 0 1rem;

      @media (min-width: $desktop) {
        padding: 0 8.5rem;
      }
    }

    h2 {
      color: $white;
      font-size: 1.8rem;
      line-height: 2.9rem;
      font-weight: 400;

      @media (min-width: $tablet) {
        font-size: 2rem;
      }

      @media (min-width: $desktop) {
        font-size: 3.3rem;
        line-height: 4.7rem;
      }
    }

    p {
      padding-right: 8rem;
      margin-bottom: 0;
      color: $white;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.9rem;

      @media (min-width: $tablet) {
        font-size: 1.2rem;
      }

      @media (min-width: $desktop) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }

  .left-arrow {
    display: none;
    left: -0.5rem;

    @media (min-width: $tablet) {
      display: block;
      left: 2rem;
    }

    @media (min-width: $desktop) {
      left: 1rem;
    }
  }

  .right-arrow {
    display: none;
    right: -0.5rem;

    @media (min-width: $tablet) {
      display: block;
      right: 2rem;
    }

    @media (min-width: $desktop) {
      right: 1rem;
    }
  }

  .left-arrow,
  .right-arrow {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 2rem;

      @media (min-width: $tablet) {
        width: 3rem;
      }

      @media (min-width: $desktop) {
        width: 3.7rem;
      }
    }
  }
}
