.space-list-item {
  overflow: hidden;
  background: $veryLightBlueGrey;
  border-left: 0.6rem solid;
  color: $darkGrey;
  display: flex;
  height: 8.5rem;
  justify-content: flex-start;
  line-height: 1.8rem;
  margin-bottom: 0.1rem;
  position: relative;

  @media (min-width: $landscape-mobile) {
    height: 12rem;
    margin-bottom: 0.3rem;
  }

  &--clinical {
    border-left-color: $darkBlue;
  }

  &--non-clinical {
    border-left-color: $pink;
  }

  &__image-container {
    align-items: center;
    border-right: 1px solid $white;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 8rem;
    position: relative;

    @media (min-width: $landscape-mobile) {
      width: 13.5rem;
    }

    img {
      height: 100%;
    }

    span {
      color: $darkGrey;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__details-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: stretch;

    width: calc(100% - 8rem);
    padding: 1rem;

    @media (min-width: $landscape-mobile) {
      padding: 2rem;
    }
  }

  &__booking,
  &__details {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  &__details {
    &__name {
      margin-bottom: 0;
      color: $darkBlueGrey;
      font-size: 1.2rem;
      font-weight: 600;

      @media (min-width: $landscape-mobile) {
        font-size: 1.6rem;
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__venue-name {
      display: none;
      margin-bottom: 0.5rem;
      color: $darkBlueGrey;
      font-size: 1.3rem;
      font-weight: 500;

      @media (min-width: $landscape-mobile) {
        display: block;
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__feature-list {
      display: flex;
      margin-top: auto;
    }
  }

  &__booking {
    align-items: flex-end;
    margin-left: auto;

    font-size: 1.3rem;
    text-align: right;

    button {
      margin-top: auto;
      line-height: 2rem;

      @media (min-width: $landscape-mobile) {
        line-height: $button-height-medium;
      }
    }
  }
}
