.link {
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    > a {
      display: inline-block;  /* For IE11/ MS Edge bug */
      pointer-events: none;
      text-decoration: none;
    }
  }
}
