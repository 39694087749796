.my-account-report-table {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.4;
  overflow: auto;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  th,
  td {
    border: 1px solid $white;
    border-bottom-color: $darkBlueGrey;
    padding: 1rem;
  }

  thead {
    th {
      background-color: $darkBlueGrey;
      color: $white;
    }
  }

  tbody {
    td {
      background-color: $veryLightBlueGrey;
    }

    tr:nth-of-type(even) {
      td {
        background-color: $lightGrey;
      }
    }
  }
}
