.booking_confirmation {
  &__heading {
    display: flex;
    flex-direction: row;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    > div {
      display: flex;
      flex-direction: row;

      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }

    h2 {
      margin-bottom: 0.5rem;
    }

    h3 {
      font-weight: 500;

      @media (max-width: $big-mobile) {
        font-weight: inherit;
      }
    }
  }

  &__print {
    align-self: flex-end;
    margin-bottom: 2rem;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__add-to-calendar {
    align-self: flex-end;
    margin: 0 1rem 2rem 0;
  }

  &__logo {
    margin-right: 10rem;
    position: relative;
    top: -0.5rem;
    width: 15%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__text {
    width: 71%;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}
