.booking-times {
  padding: 2rem;

  @media (min-width: $tablet) {
    padding: 2rem 0;
  }

  &__heading {
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 2.4rem;
    color: $darkGrey;
    padding-bottom: 1rem;
  }

  &__text {
    font-size: 1.6rem;
  }

  &__table-link {
    display: inherit;
    margin: 2rem 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 3.4rem;
    background: transparent;
    border: none;

    @media (min-width: $medium-mobile) {
      font-size: 2.1rem;
    }

    &--clinical {
      color: $darkBlue;
    }

    &--non-clinical {
      color: $pink;
    }
  }

  &__table-container {
    background-color: #E2E8E9;
    overflow-x: auto;
    white-space: nowrap;

    &--open {
      display: block;
    }

    &--closed {
      display: none;
    }
  }

  &__time-table-close-button {
    border: none;
    background: transparent;
    margin-left: auto;
    display: flex;
    font-family: Hind, Verdana, Tahoma, sans-serif;
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: $darkBlueGrey;
    padding: 1rem 1rem 0 0;

    img {
      padding-left: 0.5rem;
    }
  }

  &__times-table {
    padding: 1rem 0 2rem 0;

    @media (min-width: $tablet) {
      padding: 2rem 1rem;
    }

    &--open {
      display: block;
    }

    &--closed {
      display: none;
    }
  }

  &__non-clinical-times-table {
    width: 100%;
    overflow-x: auto;

    tbody {
      tr {
        line-height: 3.7rem;

        &:nth-child(2n+1) {
          background: $veryLightBlueGrey;
        }
      }
    }
  }

  &__hours {
    margin-left: auto;
    font-size: 1.6rem;
    color: $darkBlueGrey;

    padding: 0 2rem;

    @media (min-width: $tablet) {
      padding: 0 3rem;
    }
  }

  &__clinical-times-table {
    width: 100%;
    overflow-x: auto;

    thead th {
      font-weight: 600;
      line-height: 3.7rem;
      color: $darkBlueGrey;
    }

    tbody {
      tr {
        line-height: 3.7rem;

        &:nth-child(2n+1) {
          background: $veryLightBlueGrey;
        }
      }
    }
  }

  &__slot-hours {
    text-align: center;
    padding: 0 2rem;

    @media (min-width: $tablet) {
      padding: 0 4rem;
    }
  }

  &__day {
    padding-left: 1rem;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.6rem;
    color: $darkBlueGrey;

    @media (min-width: $landscape-mobile) {
      width: 15rem;
    }
  }
}
