.block {
  background-color: $lightBlueGrey;
  margin-bottom: 3rem;

  p {
    margin: 1rem 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: $darkBlueGrey;

    &.intro {
      font-weight: 500;
      margin-bottom: 2rem;
    }

    &.note {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
  }

  &--with-border {
    border-top: 3px solid $midBlueGrey;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--no-gutter {
    padding: 0;
  }

  &__title {
    align-items: center;
    display: flex;
    margin: 0 0 1.2rem;
    padding: 1rem 0;
  }

  &__title-heading {
    color: $darkBlueGrey;
    font-size: 2.1rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  &__title-action {
    margin-left: auto;
  }

  &__title-action-link {
    background: transparent none;
    border: 0;
    color: $black;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: underline;
    top: -2px;
  }

  &__inner {
    padding: 2rem;
  }
}
