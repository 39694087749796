.content-block {
  &--core-aims {
    width: 100vw;
    padding: 12rem 1.8rem 4rem;
    background: $lightBlueGrey;
  }

  &__heading {
    margin-bottom: 3rem;
    @include heading;
    font-size: 2.1rem;
  }

  &__subheading {
    margin: -2rem 0 4rem;
    font-size: 2rem;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__block {
    width: calc((100vw - 5.4rem) / 2);
    margin-bottom: 3.5rem;
    text-align: center;

    &:nth-of-type(odd) {
      margin-right: 1.8rem;
    }
  }

  &__icon {
    margin-bottom: 1rem;

    img {
      height: 10vh;
      width: auto;
    }
  }

  &__text {
    color: $darkGrey;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @media screen and (min-width: $tablet) {
    padding: 15rem 8rem 11rem;

    &__content {
      max-width: 104rem;
      margin: 0 auto;
    }

    &__block {
      width: calc((100vw - (8rem * 2 + 1.2rem * 3)) / 4);
      margin: 0 1.2rem 0 0;

      &:nth-of-type(odd) {
        margin-right: 1.2rem;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    &__text {
      font-size: 1.5vw;
    }
  }

  @media screen and (min-width: $desktop) {
    &__block {
      width: calc((104rem - (1.2rem * 3)) / 4);
    }

    &__text {
      font-size: 1.8rem;
    }
  }
}
