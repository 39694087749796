.header {
  display: none;

  @media screen and (min-width: $tablet) {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 2.5rem 3rem 3rem 4rem;

    &__nav {
      display: flex;
    }

    &__logo {
      margin-right: 4rem;

      img {
        height: 4rem;
        width: auto;
      }

      &--sticky {
        display: none;
        position: relative;
        top: 0.3rem;
        margin-right: 3rem !important;

        img {
          height: 4rem !important;
        }
      }
    }

    &__nav-list {
      display: flex;
      padding: 1.5rem 0 0;
      margin-bottom: -1.5rem;

      color: $darkGrey;
      font-size: 1.5rem;
      font-weight: 700;

      a,
      .dropdown {
        text-shadow: 0 0 0.4rem $white;
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      & > li {
        margin-right: 2rem;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .button {
        top: -1.4rem;
        font-weight: 600;
      }

      &--pull {
        margin-left: auto;

        li {
          margin-right: 1.5rem;
        }
      }
    }

    &__nav-item {
      .button {
        text-shadow: none;

        &--outline {
          background-color: $white;
        }
      }
    }

    .form--quicksearch {
      display: none;
    }

    .error {
      position: absolute;
      display: inline-block;
      margin: 0.5rem 0;
      padding: 1rem;
      background-color: $white;
      color: $red;
    }

    .basket-link {
      position: relative;
      padding-right: 2rem;

      &__count {
        position: absolute;
        top: -0.2rem;
        left: 4.1rem;
        min-width: 1.8rem;
        height: 1.8rem;
        margin-left: 0.7rem;
        border-radius: 0.9rem;
        color: #fff;
        background: $green;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;
        text-align: center;
      }
    }

    .dropdown {
      position: relative;
      cursor: pointer;

      img {
        margin-left: 0.7rem;
      }

      &__content {
        position: absolute;
        display: none;
        left: 0;
        top: 0;
        z-index: 1;
        min-width: 160px;
        padding-top: 3rem;

        ul {
          padding: 0rem;
          background: $white;
          box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.22);

          li {
            margin-right: 0;

            a {
              display: block;
              padding: 1.5rem 1.5rem 1rem;
              color: inherit;
              text-decoration: none;

              &:hover {
                background-color: $lightGrey;
              }
            }
          }
        }
      }

      &:hover {
        .dropdown__content {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: $small-desktop) {
    &__logo {
      margin-right: 8.5rem;

      img {
        height: 5rem;
        width: auto;
      }
    }

    &__nav-list {
      li {
        margin-right: 3.5rem;
      }

      .button {
        padding: $button-padding-top 3rem $button-padding-bottom;
      }
    }

    &__nav-list--pull {
      li {
        margin-right: 2.5rem;
      }
    }
  }
}

#home .properties {
  display: none;
}

.header--sticky {
  @media screen and (min-width: $tablet) {
    position: sticky;

    padding: 2rem 2rem 1.7rem;
    background: $white;

    .header__logo {
      &--absolute {
        display: none;
      }

      &--sticky {
        display: block;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .form--quicksearch {
      display: flex;
      margin-right: 3rem;
    }

    .header__nav-list {
      li.find-rooms {
        display: none;
      }
    }
  }
}
