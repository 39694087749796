.venue__header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    padding: 2rem;
  }

  @media (max-width: $tablet) {
    padding: 0 2rem;
  }

  .venue__organisation {
    @media (max-width: $tablet) {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-top: 2rem;
      align-items: center;
    }
  }

  .venue__organisation-icon {
    text-align: right;

    @media (max-width: $tablet) {
      flex: 1;
      padding-left: 1rem;
    }

    @media (min-width: calc(#{$tablet} + 1px)) {
      padding-bottom: 0.5rem;
    }

    img {
      height: 3.438rem;
      width: auto;
    }
  }

  .venue__organisation-name {
    margin-top: auto;

    span {
      font-weight: bold;

      @media (max-width: $tablet) {
        padding-bottom: 1rem;
        display: block;
      }
    }
  }

  .venue__details-address {
    margin-top: auto;
  }

  .venue__heading {
    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
}
