/* fonts */
$primaryFontFamily: 'Hind', sans-serif;
$iconFontFamily: 'Font Awesome 5 Free';

/* measurements */
$maxWidth: 135rem;
$outerGutter: 3rem;
$mobileHeaderHeight: 6.5rem;

/* media queries */
$small-mobile: 320px;
$medium-mobile: 360px;
$big-mobile: 480px;
$landscape-mobile: 568px;
$tablet: 768px;
$small-desktop: 1000px;
$desktop: 1200px;
$large-desktop: 1400px;
$extra-large-desktop: 1920px;
