.send-email {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 60rem;
  padding: 0 2rem;

  @media (min-width: $tablet) {
    background-position: right center;
    background-size: cover;
    height: 100vh;
  }

  &__content {
    background: $veryLightBlueGrey;
    border-radius: 0.3rem;
    box-shadow: 0 0 1rem 0 $midGrey;
    display: block;
    margin: 0 auto;
    padding: 4.2rem 3rem;
    text-align: center;
    width: 46rem;

    @media (min-width: $tablet) {
      padding: 3rem 4.2rem;
    }
  }

  &__heading {
    color: $green;
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 4.2rem;
    text-align: center;

    @media (min-width: $medium-mobile) {
      font-size: 2.6rem;
    }
  }

  &__link {
    color: $darkGrey;
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-top: 2rem;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
    }
  }
}

.confirmation {
  align-items: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 70rem;
  padding: 0 2rem;

  @media (min-width: $tablet) {
    background-position: right center;
    background-size: cover;
    height: 100vh;
  }

  &__content {
    background: $veryLightBlueGrey;
    border-radius: 0.3rem;
    box-shadow: 0 0 1rem 0 $midGrey;
    display: block;
    margin: 0 auto;
    padding: 4.2rem 3rem;
    text-align: center;
    width: 46rem;
  }

  &__heading {
    color: $green;
    font-size: 2.2rem;
    font-weight: bold;

    @media (min-width: $medium-mobile) {
      font-size: 2.6rem;
    }
  }

  &__text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2.1rem;
    margin: 0 auto;
    max-width: 35rem;
    padding-bottom: 2rem;
  }

  &__icon {
    padding-bottom: 3rem;
  }

  &__link-text {
    font-size: 1.3rem;
    line-height: 2.2rem;
    margin-bottom: 1rem;
  }

  &__link {
    color: $darkGrey;
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Reset main element css */
main.resend_confirmation_page,
main.reset_password_page {
  max-width: initial;
  padding: 0;
}
