.facets-equipment {
  columns: 2;

  &__item {
    width: 100%;
    margin: 1rem 0;
    display: inline-block;
  }

  h3 {
    font-size: 1.6rem;

    @media (min-width: $medium-mobile) {
      font-size: 2rem;
    }
  }

  ul {
    margin-left: 2rem;
  }

  li {
    list-style-type: initial;
    line-height: 2rem;
    font-size: 1.4rem;

    @media (min-width: $medium-mobile) {
      font-size: 1.6rem;
    }
  }
}
