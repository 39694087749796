#slideShow {
  position: relative;

  .slideshow {
    &__container {
      overflow: hidden;
      width: 100%;
    }

    &__map-toggle-btn {
      height: 4.8rem;
      outline: none;
      padding: 0 0.5rem;

      position: absolute;
      right: 7rem;
      width: auto;
      bottom: 2.4rem;

      &__desktop {
        display: none;
      }

      @media (min-width: $landscape-mobile) {
        &__desktop {
          display: unset;
        }

        &__mobile {
          display: none;
        }

        padding: 0 2rem;
        right: 1rem;
        width: auto;
      }
    }

    &__svg {
      display: block;
      height: 4.8rem;
      width: 4.8rem;
    }
  }
}
