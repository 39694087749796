#quickBook {
  .field--has-error {
    > div {
      border: 2px solid red;
      border-radius: 3px;
    }
  }

  .block__title {
    padding: 0;
  }

  .datepicker-field {
    width: 100%;
  }

  .booking-slots {
    margin-bottom: 3rem;
  }

  .space-list {
    padding: 0 2rem;
  }

  .booking-total {
    color: $darkBlueGrey;
    padding: 1rem 2rem;
  }

  .host-select {
    margin: 0 2rem 2rem;

    .field {
      margin-bottom: 0;

      div[class*='Placeholder'] {
        position: relative;
      }
    }
  }
}

.quick-book {
  &__selected_venue {
    font-weight: 600;
    padding: 0 2rem 1rem;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__link {
    margin: 0 2rem 0 0;
    text-align: right;
  }

  &__clash-alert {
    background-color: #c9d4d5;
    margin: 2rem -2rem;
    padding: 2rem 4rem;

    &--faq {
      background-color: $red;
      margin-bottom: 1rem;

      .quick-book__clash-alert-text {
        color: $white;
        margin: 0;
        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  &__clash-alert-text {
    color: $darkGrey;
    font-weight: 600 !important;
    text-align: center;
  }

  &__clash-alert-link {
    color: $white;
    text-decoration: underline;
  }

  &__add_room {
    margin-left: 2rem;
    color: $darkBlueGrey;
    font-weight: 600;
    text-decoration: none;

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  &__tooltip {
    padding: 1.35rem 0 0 0.5rem;

    .react-tooltip-lite-arrow {
      display: none;
    }

    .react-tooltip-lite {
      margin-left: 0.1rem;
      margin-top: 1.2rem;
    }

    .react-tooltip-lite::after {
      background-color: #e8edee;
      border: 1px solid #b7b7b7;
      border-left-width: 0;
      border-top-width: 0;
      bottom: -6px;
      content: '';
      height: 10px;
      left: calc(90% - 4px);
      position: absolute;
      transform: rotate(45deg);
      width: 10px;

      @media (min-width: $landscape-mobile) {
        left: calc(50% - 4px);
      }
    }
  }
}
