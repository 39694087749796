.form {
  &--inline {
    display: block;

    @media (min-width: $tablet) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__field {
    display: block;
    margin-right: 0;
    position: relative;

    @media (min-width: $tablet) {
      margin-right: 1.5rem;
    }
  }
}
