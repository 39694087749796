.my-account-main-panel--accounts {
  .accounts-filters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .field,
    .form__field {
      margin: 0 0 1rem !important;
    }

    .form__field > .field {
      margin: 0 !important;
    }

    &__date-range {
      display: flex;

      .datepicker-field {
        flex-basis: 50%;

        .field {
          margin-bottom: 1rem;
        }

        &:last-of-type {
          .datepicker-field__popup {
            left: auto;
            right: 0;
          }
        }
      }
    }

    &__services-statuses {
      display: flex;

      .form__field {
        flex-basis: 50%;
        margin: 0;

        &:first-of-type {
          margin-right: 0.5rem;
        }
        &:last-of-type {
          margin-left: 0.5rem;
        }
      }
    }

    .button--dropdown {
      height: 4rem;
      line-height: 4rem;
      padding: 0 1.5rem;
      color: #757575;
      font-weight: 500;
      font-size: 1.6rem;
    }

    .field--buttons {
      margin-top: 1rem !important;
      text-align: center;
    }

    .field__input input,
    .button--dropdown,
    .datepicker-field .field--text {
      font-size: 1.4rem;
    }
  }

  &.my-account-main-panel--wide {
    .my-account-filters {
      & > .field {
        flex-basis: 24%;
      }

      .field--text,
      .field--select-list {
        margin-right: 1rem !important;
      }

      .form__field {
        flex-basis: 24%;
        margin-right: 1rem !important;
      }

      .field--buttons {
        margin: 0 0 1rem 0 !important;
        text-align: right;
      }

      .field__input input,
      .button--dropdown,
      .datepicker-field .field--text {
        font-size: 1.4rem;
        letter-spacing: -0.1px;
      }

      .field__input input,
      .button--dropdown {
        padding: 0 1rem;
      }

      .field__input input,
      .button--dropdown,
      .datepicker-field .field__input {
        &::after {
          right: 1rem;
        }
      }

      &__date-range,
      &__group {
        display: flex;
        flex-basis: 48%;

        .form__field {
          flex-basis: 50%;

          &:last-of-type {
            margin-right: 0 !important;
          }
        }
      }

      &__group {
        flex-basis: calc(48% + 1rem);
      }

      &__date-range {
        .field {
          margin-right: 0 !important;
        }
      }

      .badge {
        margin: 0 0.5rem;
      }
    }
  }
}
