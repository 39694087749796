.instructional-form {
  font: normal 400 1.5rem/1.4 $primaryFontFamily !important;
  text-align: left !important;

  h2,
  h3,
  h4 {
    font: normal 700 2.8rem/1.4 $primaryFontFamily;
    margin-bottom: 1rem;
  }

  h2 {
    color: $green;
  }

  h3 {
    font-size: 2.1rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  p,
  ul {
    font: inherit !important;
    margin-bottom: 1rem !important;

    a {
      color: inherit;
    }
  }

  ul {
    list-style: disc;

    li {
      margin: 0 0 0.5rem 2rem;
    }
  }

  &__actions {
    margin-top: 4rem;
    text-align: center;
  }

  &__form {
    background: $lightBlueGrey;
    box-shadow: 0 0 1rem $midGrey;
    color: $darkGrey;
    font: normal 400 1.4rem/1.4 $primaryFontFamily !important;
    margin: 2rem 2rem 4rem;
    padding: 2rem 2rem 1rem;

    p,
    ul {
      font: inherit !important;
    }
    @media (max-width: $tablet) {
      box-shadow: none;
      margin: 0;
      padding: 2rem 0;
    }
  }

  &__fields {
    margin-bottom: 1rem;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .field--checkbox {
      margin-bottom: 0.5rem;

      label {
        white-space: nowrap;
      }
    }
  }

  &__instructions {
    background: $darkBlueGrey;
    color: $white;
    padding: 3rem 2rem 4rem;
  }

  @media (min-width: $tablet) {
    box-shadow: 0 0 1rem $midGrey;
    display: flex;
    max-width: 90rem;
    margin: auto;

    &__form,
    &__instructions {
      padding: 4rem 2.5rem 0rem;
    }

    &__form {
      box-shadow: none;
      margin: 0 auto;
      flex: 1;
    }

    &__instructions {
      flex: 1;
    }

    &__checkboxes {
      flex-direction: row;

      .field--checkbox {
        margin-bottom: 0;
      }
    }
  }

  .field--checkbox-list__item {
    margin-left: 1rem;
  }
}
