.payment-summary {
  &_details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    dt, dd {
      padding: 0 0 1rem 0;
    }

    dt {
      width: 33%;
      font-weight: bold;
    }

    dd {
      width: 60%;
    }
  }
}