.switch {
  align-items: center;
  display: flex;

  &__button {
    background-color: #dcdada;
    border: 0;
    border-radius: 1.4rem;
    cursor: pointer;
    height: 2.7rem;
    margin: 0;
    min-width: 6rem;
    padding: 0;
    position: relative;

    &::after {
      background-color: red;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 2.1rem;
      position: absolute;
      top: 0.3rem;
      width: 2.1rem;
    }
  }

  &__label {
    color: $black;
    font-size: 1.4rem;
    font-weight: 400;
    margin-right: 1rem;
  }

  &__text {
    color: $black;
    font-size: 1.1rem;
    font-weight: 400;
    position: absolute;
    text-transform: uppercase;
    top: 0.7rem;
  }

  &--on {
    .switch__text {
      left: 1rem;
    }

    .switch__button {
      &::after {
        background-color: $green;
        left: calc(100% - 2.5rem);
      }
    }
  }

  &--off {
    .switch__text {
      right: 0.7rem;
    }
    .switch__button {
      &::after {
        background-color: $orange;
        left: 0.4rem;
      }
    }
  }
}
