.mobile-header {
  .mobile-room-search {
    position: absolute;
    width: 100vw;
    height: 100vh;
    border-top: 0.1rem solid $lightGrey;
    padding: 3rem;
    background: $veryLightBlueGrey;
    text-align: center;

    &--closed {
      display: none;
    }

    &--open {
      display: block;
    }

    &__heading {
      margin-bottom: 2rem;
      @include heading;
    }
  }
}
